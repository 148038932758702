import { useCallback } from 'react';

/**
 * defines a list of feature flags
 * interface has a local visibility
 */
export interface FeatureFlags {
	'not-a-real-flag': boolean;
}

const featureFlags: any = {};

export const useFeatureFlag = (
	flagName: keyof FeatureFlags,
): boolean | string | undefined => featureFlags[flagName];

export const setFeatureFlag = (
	flagName: keyof FeatureFlags,
	value: boolean | string,
): void => {
	featureFlags[flagName] = value;
};

export const getFeatureFlags = (): FeatureFlags => featureFlags;

const configuration: any = {};

/**
 * defines a list of runtime confuguration flags
 * interface has a local visibility
 */
export interface RuntimeConfiguration {
	// empty
}

export const useRuntimeConfigurationRef = (): {
	current: RuntimeConfiguration;
} => ({
	get current() {
		return configuration;
	},
});

export const useRuntimeConfiguration = () => {
	return useCallback((alter: Partial<RuntimeConfiguration>) => {
		Object.assign(configuration, alter);
	}, []);
};

export const experimentOf = <T extends ReadonlyArray<string | undefined>>(
	set: T,
): Exclude<T[number], undefined> => {
	const reducedSet = set.filter(Boolean);

	return reducedSet[Math.floor(Math.random() * reducedSet.length)] as any;
};
