import { encodePersonalToken } from '@smile/personal-token';
import type {
	PersonalInformationRequestDetails,
	PersonalInformationRequestSubject,
	PersonalInformationTransfer,
} from '@thanksjs/web';
import { getPiiId } from '@theway/uid';

import { tunnel } from '../../bridge';

export const getPiiToken = async ({
	impression,
	piiRegion,
	subject,
	offerType,
	offer,
	customerSharedEmail,
}: {
	impression: string;
	piiRegion: string;
	subject: PersonalInformationRequestSubject;
	offerType: 'coupon' | 'subscription' | 'transaction';
	offer: {
		partnerName: string;
		experienceId: string;
	};
	customerSharedEmail: undefined | string;
}): Promise<string | undefined> => {
	const token = getPiiId('collected', piiRegion);
	const info: PersonalInformationRequestDetails = {
		token,
		offerType: offerType,
		advertiser: offer.partnerName,
	};

	return new Promise((resolve) => {
		const off = tunnel.on(
			'personal-information-response',
			({
				info,
				pii,
			}: {
				info: PersonalInformationRequestDetails;
				pii: PersonalInformationTransfer;
			}) => {
				if (info.token !== token) {
					return;
				}

				if (!pii) {
					if (customerSharedEmail) {
						resolve(
							encodePersonalToken(impression, {
								piId: token,
								email: customerSharedEmail,
							}),
						);
					} else {
						resolve(undefined);
					}

					return;
				}

				off();

				resolve(
					encodePersonalToken(impression, {
						piId: token,
						firstName: pii.firstName,
						email: pii.email,
					}),
				);
			},
		);

		tunnel.send('personal-information-request', { subject, info });
	});
};
