/** DO NOT RENAME THIS FILE
 * or dont forget update "sideEffects" of widget
 */

import '@theway/helpers-css/reset';
import '@smile/font-assets';

import { lazyInitWidgetAnalytics } from './analytics/init';
import { initSentry } from './sentry';

// ----
lazyInitWidgetAnalytics();
initSentry();
