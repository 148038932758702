import { registerPendingPromise } from '@theway/continuity';

export type ErrorHandler = (e: Error) => void;

let currentHandler: ErrorHandler | undefined;

export const registerErrorHandler = (handler: ErrorHandler) => {
	currentHandler = handler;
};

export const captureException = <T extends Error>(e: T): T => {
	console.error('captureException', e);

	if (currentHandler) {
		currentHandler(e);
	} else {
		// using a "pair" of timeout and promise to avoid unhandled promise rejection
		setTimeout(() => {
			throw e;
		}, 0);

		registerPendingPromise(
			new Promise((resolve) => setTimeout(resolve, 0)),
		);
	}

	return e;
};
