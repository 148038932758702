export const font = {
	family: {
		primary: "'Gramatika', 'Gramatika Fallback', sans-serif",
	},
	weight: {
		regular: 400,
		medium: 500,
		bold: 700,
		black: 900,
	},
} as const;
