import type { FC, PropsWithChildren } from 'react';

import { ContextContentTeleporter } from '../control/teleporters';
import type { FooterProps } from '../footer';
import { Footer } from '../footer';

import { Surface, type SurfaceColorType } from '../surface';

import * as styles from './styles';
import type { LayoutThemeType, ThemeProps } from './types';

const pickColors = (
	theme: LayoutThemeType,
): Record<string, SurfaceColorType> => {
	switch (theme) {
		case 'light':
			return { content: 'primary' };
		case 'dark':
			return { content: 'tertiary' };
	}
};

export const Content: FC<PropsWithChildren<FooterProps & ThemeProps>> = ({
	children,
	theme,
	terms,
}) => {
	const { content } = pickColors(theme);

	return (
		<Surface as="div" color={content} className={styles.content}>
			<div>
				<ContextContentTeleporter.Target />
				<ContextContentTeleporter.HiddenFallback>
					<div className={styles.contentWrapper}>{children}</div>
				</ContextContentTeleporter.HiddenFallback>
			</div>
			<Footer terms={terms} />
		</Surface>
	);
};
