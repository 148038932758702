import type {
	BaseOfferSlot,
	ImpressionSequence,
} from '@thanks/impression-type';
import type { ReactNode } from 'react';

import { cachedLoad, preloadCache } from './load-cache';

type ExtractDataType<
	T extends BaseOfferSlot['type'],
	U extends BaseOfferSlot,
> = U extends { type: T; data: infer D } ? D : never;

type OfferData<T extends BaseOfferSlot['type']> = ExtractDataType<
	T,
	BaseOfferSlot
>;

type SlideType = {
	img: string;
	bg: string;
	stroke?: string;
};

type SlotSharedProps = {
	slides: SlideType[];
	activeSlide: number;
	maximumSlide: number;
	isLastOffer: boolean;
	onSetSlide(slide: number): void;
	withoutBrand: boolean;
};

type SlotEvents = {
	onPrimaryAction(): void;
	onSecondaryAction(action: string): void;
	onForward(): void;
	onNext(): void;
};

type SlotProps = SlotSharedProps & SlotEvents;

type SlotRenderer<Component, T, Data, OwnProps> = (
	type: T,
	component: Component,
	data: Data,
	props: SlotProps,
	impression: ImpressionSequence,
	ownProps: OwnProps,
) => ReactNode;

export const renderer = <
	T extends BaseOfferSlot['type'],
	Component,
	OwnProps = {},
>(
	type: T,
	importer: () => Promise<Component>,
	factory: SlotRenderer<Component, T, OfferData<T>, OwnProps>,
) => {
	return {
		type,
		importer: importer as unknown,
		factory: factory as SlotRenderer<unknown, T, OfferData<T>, OwnProps>,
	} as const;
};

const defaultRendererFallbackFactory = (type: string) => ({
	factory: (() => <div>unknown type {type}</div>) as SlotRenderer<
		null,
		any,
		{},
		{}
	>,
	importer: () => Promise.resolve(() => <div>unknown type {type}</div>),
});
const defaultRendererFallbackTypes: Record<
	string,
	ReturnType<typeof defaultRendererFallbackFactory>
> = {};

const defaultRendererFallback = (type: string) => {
	if (!defaultRendererFallbackTypes[type]) {
		defaultRendererFallbackTypes[type] =
			defaultRendererFallbackFactory(type);
	}

	return defaultRendererFallbackTypes[type];
};

type ExtractFactoryType<
	X extends { type: string; factory: unknown },
	T extends X['type'],
> = X extends { type: T; factory: infer D }
	? { [key in T]: { factory: D; importer: any } }
	: never;

type ExtractFactories<
	T extends ReadonlyArray<{
		type: string;
		factory: SlotRenderer<any, any, any, any>;
	}>,
> = ExtractFactoryType<T[number], T[number]['type']>;

export const composeFactory = <
	K extends string,
	T extends ReadonlyArray<{
		type: K;
		factory: SlotRenderer<any, any, any, any>;
		importer: any;
	}>,
>(
	slots: T,
): Readonly<ExtractFactories<T>> => {
	return slots.reduce((acc, slot) => {
		acc[slot.type] = {
			factory: slot.factory,
			importer: slot.importer,
		};

		return acc;
	}, {} as any);
};

type GetOwnProps<T> =
	T extends Record<any, { factory: SlotRenderer<any, any, any, infer U> }>
		? U
		: never;

export type SlotBroker<Types, OwnProps> = {
	preload(type: Types): Promise<void>;
	preloadAll(): Promise<any>;
	render(
		slot: BaseOfferSlot,
		props: SlotProps,
		impression: ImpressionSequence,
		ownProps: OwnProps,
	): ReactNode;
};

export const createSlotRender = <
	T extends ReadonlyArray<{
		type: string;
		factory: SlotRenderer<any, any, any, any>;
	}>,
>(
	TYPES: Readonly<ExtractFactories<T>>,
): SlotBroker<keyof typeof TYPES, GetOwnProps<typeof TYPES>> => ({
	preload: (type) => {
		const { importer } =
			TYPES[type] ?? defaultRendererFallback(type as string);

		return preloadCache(importer);
	},

	preloadAll: () => {
		const promises = Object.values(TYPES).map(({ importer }: any) =>
			preloadCache(importer),
		);

		return Promise.all(promises);
	},

	render: (slot, props, impression, ownProps) => {
		const { factory, importer } =
			TYPES[slot.type as keyof typeof TYPES] ??
			defaultRendererFallback(slot.type);

		const imported = cachedLoad(importer);

		if (!imported) {
			console.error('cannot find importer for ', slot.type, { importer });
			throw new Promise(() => {
				/* and never resolve */
			});
		}

		return factory(
			slot.type,
			imported,
			slot.data,
			props,
			impression,
			ownProps,
		);
	},
});
