import { css } from '@linaria/core';

export const lightTheme = css`
	--text-primary: #1a1a1a;
	--text-muted: #333333;
`;

export const darkTheme = css``;

export const contrastTheme = css``;

export const component = css``;

export const primary = css`
	background-color: #ffffff;
`;

export const secondary = css``;

export const tertiary = css``;
