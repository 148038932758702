import { useDisplayMode } from '@smile/context';
import { VisuallyHidden } from '@smile/ds/a11y';
import { useEscHandler } from '@smile/ds/a11y/esc-handler';
import { IconButton } from '@smile/ds/actions';
import { CloseIcon } from '@smile/ds/icons/Close';
import { Surface } from '@smile/ds/surface';
import { useRuntimeConfigurationRef } from '@smile/experimentation';
import { useAnalyticsEvent } from '@theway/analytics';
import { PopupTeleporterTarget } from '@theway/shards';
import { clsx } from 'clsx';
import type { CSSProperties, FC, PropsWithChildren } from 'react';

import { useCallback } from 'react';

import { AsteriskProvider } from '../control/state-controller';

import {
	ContextBadgeTeleporter,
	ContextCloseTeleporter,
} from '../control/teleporters';

import { Badge } from './Badge';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import * as styles from './styles';
import type { CommonProps, LayoutProps } from './types';

declare module '@smile/experimentation' {
	interface RuntimeConfiguration {
		/**
		 * controls "esc" key behavior
		 */
		disableEscClose: boolean;
	}
}

export const Layout: FC<
	PropsWithChildren<
		CommonProps &
			LayoutProps & { isPreview?: boolean; style?: CSSProperties }
	>
> = ({
	onClose,
	children,
	behaviour = ['normal', 'normal'],
	isPreview = false,
	style,
	...props
}) => {
	const uiEvent = useAnalyticsEvent();
	const configurationRef = useRuntimeConfigurationRef();
	const onEscClose = useCallback(() => {
		if (configurationRef.current.disableEscClose) {
			uiEvent('esc-widget-suppressed', null);
		} else {
			onClose();
		}
	}, [configurationRef, onClose, uiEvent]);

	useEscHandler(onEscClose, 'widget');

	const displayMode = useDisplayMode();

	return (
		<AsteriskProvider
			hasAsterisk={Boolean(props.terms || props.emailNotification)}
		>
			<div
				className={clsx(
					styles.component,
					styles[`${behaviour[0]}Mobile`],
					styles[`${behaviour[1]}Desktop`],
					isPreview && styles.preview,
				)}
				style={style}
			>
				<VisuallyHidden as="h1">
					{props.statusText} Here is your compliment. Please press ESC
					to close this window.
				</VisuallyHidden>
				<Surface
					as="div"
					className={styles.componentWrapper}
					tabIndex={-1}
				>
					{displayMode === 'mobile' && (
						<Mobile onClose={onClose} {...props}>
							{children}
						</Mobile>
					)}
					{displayMode === 'desktop' && (
						<Desktop onClose={onClose} {...props}>
							{children}
						</Desktop>
					)}
					<div className={styles.close}>
						<ContextCloseTeleporter.Target>
							<IconButton
								onClick={onClose}
								icon={<CloseIcon />}
								actionName="close"
								aria-label="Close thanks widget"
							>
								Close thanks ads
							</IconButton>
						</ContextCloseTeleporter.Target>
					</div>
				</Surface>
				{displayMode === 'desktop' && (
					<div className={styles.desktop}>
						<ContextBadgeTeleporter.Target>
							{props.badge && <Badge>{props.badge}</Badge>}
						</ContextBadgeTeleporter.Target>
					</div>
				)}
				<PopupTeleporterTarget />
			</div>
		</AsteriskProvider>
	);
};
