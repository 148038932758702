import { css } from '@linaria/core';

export const component = css`
	position: absolute;
	top: 0;
	left: 0;

	width: 1px;
	height: 1px;
	padding: 0;

	white-space: nowrap;

	border: 0;

	overflow: hidden;

	clip: rect(0 0 0 0);
	clip-path: inset(100%);
`;
