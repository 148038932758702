import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';

export const component = css`
	aspect-ratio: 1/1;
`;

export const dark = css`
	--color: var(--custom-secondary-thanks-color, ${palette.powerHouse[500]});

	--bg-color: var(
		--custom-secondary-thanks-bg-color,
		${palette.baseBlue[500]}
	);

	[data-theme='zip'] & {
		--color: #aa8fff;

		--bg-color: #1a0826;
	}

	[data-theme='zip-us'] & {
		--color: #aa8fff;

		--bg-color: #1a0826;
	}

	[data-theme='humanitix'] & {
		--color: #030303;

		--bg-color: #ffb18f;
	}

	[data-theme='ebay'] & {
		--color: #ffffff;

		--bg-color: #3665f3;
	}

	[data-theme='try-booking'] & {
		--color: #ffffff;

		--bg-color: #f6b413;
	}

	[data-theme='sezzle'] & {
		--color: #382757;

		--bg-color: #ffffff;
	}
`;

export const light = css`
	--color: var(--custom-primary-thanks-color, ${palette.baseBlue[500]});

	--bg-color: var(
		--custom-primary-thanks-bg-color,
		${palette.powerHouse[500]}
	);

	[data-theme='zip'] & {
		--color: #1a0826;

		--bg-color: #aa8fff;
	}

	[data-theme='zip-us'] & {
		--color: #1a0826;

		--bg-color: #aa8fff;
	}

	[data-theme='humanitix'] & {
		--color: #030303;

		--bg-color: #ffb18f;
	}

	[data-theme='ebay'] & {
		--color: #ffffff;

		--bg-color: #3665f3;
	}

	[data-theme='try-booking'] & {
		--color: #ffffff;

		--bg-color: #f6b413;
	}

	[data-theme='sezzle'] & {
		--color: #ffffff;

		--bg-color: rgb(131, 51, 212);
	}
`;
