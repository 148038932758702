import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';

export const lightTheme = css`
	--color: ${palette.powerHouse[500]};

	[data-theme='zip'] & {
		--color: #411361;
	}

	[data-theme='zip-us'] & {
		--color: #6542be;
	}

	[data-theme='humanitix'] & {
		--color: #e59977;
	}

	[data-theme='ebay'] & {
		--color: #3665f3;
	}

	[data-theme='try-booking'] & {
		--color: #f6b413;
	}

	[data-theme='sezzle'] & {
		--color: #382757;
	}
`;

export const darkTheme = css`
	--color: var(--custom-secondary-link-color, ${palette.powerHouse[500]});

	[data-theme='zip'] & {
		--color: #6542be;
	}

	[data-theme='zip-us'] & {
		--color: #6542be;
	}

	[data-theme='humanitix'] & {
		--color: #030303;
	}

	[data-theme='ebay'] & {
		--color: #3665f3;
	}

	[data-theme='try-booking'] & {
		--color: #f6b413;
	}

	[data-theme='sezzle'] & {
		--color: #fff;
	}
`;

export const contrastTheme = css`
	--color: ${palette.baseBlue[500]};
`;

export const component = css`
	position: relative;

	font: inherit;

	color: inherit;

	border: 0;
	background-color: transparent;

	transition-timing-function: linear;
	transition-duration: 200ms;
	transition-property: all;

	&:hover,
	[data-focus-visible] &:focus-visible {
		color: var(--color);
	}

	&:disabled {
		opacity: 0.5;

		[data-theme='zip'] & {
			opacity: 1;
			color: var(--color-disabled);
		}
	}
`;

export const primary = css``;

export const secondary = css``;

export const tertiary = css``;

export const icon = css``;

export const iconBefore = css``;

export const iconAfter = css``;

export const small = css``;

export const large = css``;

export const fullWidth = css``;

export const underlined = css`
	text-decoration: underline;
`;
