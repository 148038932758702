import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';
import { customVariable } from '@theway/helpers-css/variables';

export const countVar = customVariable('count', (x) => x);
export const gapVar = customVariable('gap', (x) => `${x}px`);
export const bgVar = customVariable('background', (x) => x);
export const strokeVar = customVariable('stroke', (x) => x);

export const list = css`
	--brand-color: ${palette.powerHouse[500]};

	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;

	[data-theme='zip'] & {
		--brand-color: #aa8fff;
	}

	[data-theme='humanitix'] & {
		--brand-color: #ffb18f;
	}

	[data-theme='ebay'] & {
		--brand-color: #3665f3;
	}

	[data-theme='try-booking'] & {
		--brand-color: #f6b413;
		--thanks-color: #ffffff;
	}

	[data-theme='sezzle'] & {
		--brand-color: #382757;
	}
`;

export const experiment = css``;

export const action = css`
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 56px;
	height: 56px;

	border-radius: 50%;
	border: 2px solid ${palette.gray[200]};

	background-color: ${palette.gray[200]};

	&:disabled {
		cursor: initial;
	}

	[data-focus-visible] &:focus-visible {
		outline-color: var(--outline-color);
		outline-offset: 2px;
		outline-style: solid;
		outline-width: 3px;
	}

	& img {
		width: 100%;
		height: 100%;

		object-fit: contain;
	}
`;

export const visited = css`
	img {
		opacity: 0.4;
	}

	*:where(.${experiment}) & {
		border-color: ${String(strokeVar)};
		background-color: ${String(bgVar)};

		img {
			opacity: 1;
		}
	}
`;

export const active = css`
	border-color: ${String(strokeVar)};
	background-color: ${String(bgVar)};

	& img {
		opacity: 1;
	}

	*:where(.${experiment}) & {
		box-shadow: 0 3px 5px 0 #00000026;
	}
`;

export const logo = css`
	position: absolute;
	inset: -2px;
`;
