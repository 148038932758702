import { INIT_CMD, READY_EVENT } from './consts';
import type { Callback } from './types';
import { decodeMessage, encodeMessage, executeEvent } from './utils';

export const createWebTunnelPrimary = <
	InCommands extends string = '',
	OutCommands extends string = '',
>(
	name: string,
) => {
	const events: Record<string, Callback[]> = {};

	const channel = new MessageChannel();

	channel.port1.onmessage = ({ data }) => {
		const { cmd, payload, error } = decodeMessage(data);

		if (!error) {
			executeEvent(events, cmd, payload);
		}
	};

	function send(cmd: string, payload: any) {
		channel.port1.postMessage(encodeMessage(cmd, payload));
	}

	return {
		connect(w: Window) {
			if (!w) {
				throw new Error('Cannot find parent window');
			}

			w.postMessage(encodeMessage(INIT_CMD, name), '*', [channel.port2]);
		},
		on(cmd: typeof READY_EVENT | InCommands, handle: Callback) {
			(events[cmd] = events[cmd] || []).push(handle);

			return () => {
				events[cmd] = events[cmd].filter((cb) => cb !== handle);
			};
		},
		send(cmd: OutCommands, payload?: any) {
			send(cmd, payload);
		},
	};
};

export const createWebTunnelSecondary = <
	InCommands extends string,
	OutCommands extends string,
>(
	name: string,
	acceptanceFilter: (e: MessageEvent) => boolean = () => true,
) => {
	const events: Record<string, Callback[]> = {};
	let port: MessagePort;

	const onMessage = ({ data }: MessageEvent) => {
		const { cmd, payload, error } = decodeMessage(data);

		if (!error) {
			executeEvent(events, cmd, payload);
		}
	};

	window.addEventListener('message', (e) => {
		const { cmd, payload, error } = decodeMessage(e.data);

		if (
			!error &&
			cmd === INIT_CMD &&
			payload === name &&
			acceptanceFilter(e)
		) {
			port = e.ports[0];

			if (!port) {
				throw new Error('no port has been transferred');
			}

			port.onmessage = onMessage;
			executeEvent(events, READY_EVENT, undefined);
		}
	});

	return {
		on(cmd: typeof READY_EVENT | InCommands, handle: Callback) {
			(events[cmd] = events[cmd] || []).push(handle);
		},
		isReady() {
			return Boolean(port);
		},
		send(cmd: typeof READY_EVENT | OutCommands, payload: any) {
			if (!port) {
				console.error('trying to `send` into non initialized tunnel');

				return;
			}

			port.postMessage(encodeMessage(cmd, payload));
		},
	};
};
