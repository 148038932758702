import { css } from '@linaria/core';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const component = css`
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, sans-serif;

	${container('content', 'width < 358px')} {
		zoom: 0.96;
	}
`;

export const h1 = css`
	font-size: 38px;
	line-height: 38px;
`;

export const h2 = css`
	font-size: 24px;
	line-height: 28px;

	& strong {
		font-weight: 600;
	}
`;

export const h3 = css`
	font-size: 24px;
	line-height: 28px;

	& strong {
		font-weight: 600;
	}
`;

export const h4 = css`
	font-size: 20px;
	line-height: 24px;

	& strong {
		font-weight: 600;
	}
`;

export const textXs = css`
	font-size: 12px;
	line-height: 14px;

	& strong {
		font-weight: 600;
	}
`;

export const textSm = css`
	font-size: 14px;
	line-height: 16px;

	& strong {
		font-weight: 600;
	}
`;

export const textMd = css`
	font-size: 14px;
	line-height: 20px;

	& strong {
		font-weight: 600;
	}
`;

export const primaryColor = css`
	color: var(--text-primary);
`;

export const mutedColor = css`
	color: var(--text-muted);
`;

export const left = css`
	text-align: left;
`;

export const right = css`
	text-align: right;
`;

export const center = css`
	text-align: center;
`;

export const regular = css`
	font-weight: 400;
`;

export const bold = css`
	font-weight: 600;
`;
