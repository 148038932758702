type AsCamelCase<T extends string> = T extends ``
	? T
	: T extends `${infer Left}-${infer Right}`
		? `${Lowercase<Left>}${Capitalize<AsCamelCase<Right>>}`
		: Lowercase<T>;

export const toCamelCase = <T extends string>(string: T): AsCamelCase<T> =>
	string
		.toLowerCase()
		.replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase()) as any;
