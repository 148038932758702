import { createNanoEvents } from 'nanoevents';
import { useState, useEffect } from 'react';

export const createTouchSignal = () => {
	const emitter = createNanoEvents();

	return {
		touch: () => emitter.emit('touch'),
		useTouch() {
			const [, update] = useState({});

			useEffect(() => {
				return emitter.on('touch', () => update({}));
			}, []);
		},
	};
};
