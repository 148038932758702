type CustomVariable<T> = {
	(value: T): any;
};

export const customVariable = (
	name: string,
	mapper = (x: any): any => x,
): CustomVariable<any> => {
	const setter: any = (value: any): any =>
		({
			[`--${name}`]: mapper(value),
		}) as any;
	setter.toString = () => `var(--${name})`;

	return setter;
};
