import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';
import type { FC, PropsWithChildren } from 'react';
import { useContext, createContext } from 'react';

const asteriskProvider = createContext(false);

export const AsteriskProvider: FC<
	{ hasAsterisk: boolean } & PropsWithChildren
> = ({ children, hasAsterisk }) => (
	<asteriskProvider.Provider value={hasAsterisk}>
		{children}
	</asteriskProvider.Provider>
);

export const TnC = css`
	color: ${palette.gray[400]};
`;

export const Asterisk: FC = () => {
	const shouldRender = useContext(asteriskProvider);

	if (!shouldRender) {
		return null;
	}

	return <sup className={TnC}>*</sup>;
};
