import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';
import { customVariable } from '@theway/helpers-css/variables';

export const heightVar = customVariable('height', (x) => `${x}px`);

export const component = css`
	--brand-color: var(
		--custom-partnership-thanks-bg-color,
		${palette.powerHouse[500]}
	);

	position: relative;

	display: inline-flex;

	[data-theme='zip'] & {
		--brand-color: #aa8fff;
	}

	[data-theme='humanitix'] & {
		--brand-color: #ffb18f;
	}

	[data-theme='ebay'] & {
		--brand-color: #3665f3;
	}

	[data-theme='try-booking'] & {
		--brand-color: #03507e;
	}
`;

export const bg = css`
	width: auto;
	height: ${String(heightVar)};
`;

export const logo = css`
	position: absolute;
	inset-block: 0;
	left: 0;

	width: auto;
	height: ${String(heightVar)};

	--bg-color: var(--brand-color);
	--color: var(--custom-partnership-thanks-color, ${palette.baseBlue[500]});

	[data-theme='humanitix'] & {
		--color: #030303;
	}

	[data-theme='zip'] & {
		--color: #1a0826;
	}

	[data-theme='try-booking'] & {
		--color: #fff;
	}
`;

export const brand = css`
	position: absolute;
	inset-block: 0;
	right: 0;

	display: block;
	width: ${String(heightVar)};
	height: ${String(heightVar)};

	border-radius: 50%;
	border: 2px solid transparent;

	background-color: transparent;

	& img {
		position: absolute;
		inset: 0;

		width: 100%;
		aspect-ratio: 1/1;

		object-fit: contain;
		object-position: center;
	}
`;
