import { atom, useAtom } from 'jotai';

const communicationAvailability = atom<boolean | undefined>(undefined);
const customerSharedEmailAtom = atom<string | undefined>(undefined);

export const useCustomerCommunication = () => {
	const [communicationsAvailable, setCommunicationsAvailable] = useAtom(
		communicationAvailability,
	);
	const [customerSharedEmail, setCustomerSharedEmail] = useAtom(
		customerSharedEmailAtom,
	);

	const addCustomerSharedEmail = (email: string) => {
		setCommunicationsAvailable(true);
		setCustomerSharedEmail(email);
	};

	return {
		communicationsAvailable,
		setCommunicationsAvailable,
		customerSharedEmail,
		addCustomerSharedEmail,
	};
};
