import { css } from '@linaria/core';
import { from, Media, until } from '@theway/helpers-css/breakpoints';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const component = css`
	display: flex;
	flex-direction: column;

	padding-inline: 24px;
	padding-top: 48px;

	${container('content', 'width < 358px')} {
		padding-inline: 20px;
	}

	${from(Media.SM)} {
		width: 422px;
		padding-inline: 40px;
		padding-top: 24px;
		padding-bottom: 0;
	}
`;

export const textWrapper = css`
	display: flex;
	flex-direction: column;
	row-gap: 12px;

	${until(Media.SM)} {
		align-items: center;
	}
`;

export const text = css`
	text-align: center;

	${from(Media.SM)} {
		text-align: left;
	}
`;

export const subText = css`
	text-align: center;

	${from(Media.SM)} {
		text-align: left;
	}
`;

export const buttonsGroup = css`
	flex-grow: 1;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	row-gap: 16px;

	${until(Media.SM)} {
		margin-top: 24px;
	}
`;

export const hidden = css`
	opacity: 0;

	pointer-events: none;
`;
