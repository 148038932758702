export const ArrowRight = () => (
	<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.167 10h11.666m0 0L10 4.167M15.833 10 10 15.833"
			stroke="currentColor"
			strokeWidth={1.667}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
