import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';

export const footer = css`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	padding-inline: 16px;
	height: 25px;
`;

export const emphasised = css`
	position: relative;
	z-index: 1;

	background-color: ${palette.powerHouse[500]};
`;
