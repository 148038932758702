import { css } from '@linaria/core';
import { spacing } from '@smile/tokens/spacing';

export const component = css`
	position: absolute;
	inset: 0;

	display: grid;
	grid-template-rows: 100%;

	visibility: visible;

	overflow: hidden;
	opacity: 0;

	pointer-events: all;

	transition-property: transform, opacity;
	transition-timing-function: linear;
	transition-duration: 150ms;
`;

export const visible = css`
	opacity: 1;

	transition-property: opacity;
	transition-timing-function: linear;
	transition-duration: 150ms;
`;

export const container = css`
	position: relative;

	display: grid;
	grid-template-rows: 1fr;

	width: 100%;
	height: 100%;
	padding-inline: ${spacing.curtains};
	padding-top: 48px;
	padding-bottom: 34px;
	box-sizing: border-box;
`;

export const close = css`
	position: absolute;
	top: 6px;
	right: 10px;
`;
