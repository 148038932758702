export type PromiseContinuity = (p: Promise<any>) => void;

let currentAwaiter: PromiseContinuity | undefined;

export const registerPromiseContinuity = (awaiter: PromiseContinuity) => {
	currentAwaiter = awaiter;
};

export const registerPendingPromise = (p: Promise<any>) => {
	currentAwaiter?.(p);

	return p;
};
