import { toCamelCase } from '@theway/helpers-formats';
import { clsx } from 'clsx';
import type { PropsWithChildren, FC } from 'react';

import * as styles from './styles';

export type TypographyType = Exclude<
	keyof JSX.IntrinsicElements,
	'button' | 'a'
>;

type WeightType = 'regular' | 'medium' | 'bold' | 'black';

type TypographyProps = {
	className?: string;
	id?: string;
	as: TypographyType;
	weight?: WeightType;
	color?: 'primary' | 'muted';
	align?: 'center' | 'left' | 'right';
	size: 'h1' | 'h2' | 'h3' | 'h4' | 'text-xs' | 'text-sm' | 'text-md';
};

export const Typography: FC<PropsWithChildren & TypographyProps> = ({
	children,
	className,
	as: Component,
	size,
	weight = 'regular',
	color = 'primary',
	align = 'left',
	id,
	...props
}) => {
	return (
		<Component
			className={clsx(
				className,
				styles.component,
				size && styles[toCamelCase(size)],
				styles[weight],
				styles[`${color}Color`],
				styles[align],
			)}
			id={id}
			{...props}
		>
			{children}
		</Component>
	);
};

type HeadingProps = Omit<TypographyProps, 'size' | 'weight'> &
	PropsWithChildren;

export const H1: FC<HeadingProps> = (props) => {
	return Typography({
		size: 'h1',
		weight: 'black',
		...props,
	});
};

export const H2: FC<HeadingProps> = (props) => {
	return Typography({
		size: 'h2',
		weight: 'medium',
		...props,
	});
};

//used by partner-dashboard
export const H3: FC<HeadingProps> = (props) => {
	return Typography({
		size: 'h3',
		weight: 'medium',
		...props,
	});
};

//used by partner-dashboard
export const H4: FC<HeadingProps> = (props) => {
	return Typography({
		size: 'h4',
		weight: 'medium',
		...props,
	});
};

type TextProps = Omit<TypographyProps, 'size'> & PropsWithChildren;

export const TextXs: FC<TextProps> = (props) => {
	return Typography({
		size: 'text-xs',
		...props,
	});
};

export const TextSm: FC<TextProps> = (props) => {
	return Typography({
		size: 'text-sm',
		...props,
	});
};

export const TextMd: FC<TextProps> = (props) => {
	return Typography({
		size: 'text-md',
		...props,
	});
};
