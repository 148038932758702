import type { FC, PropsWithChildren } from 'react';
import { useContext, useMemo } from 'react';

import type { Context } from './context';
import { context } from './context';
import { getDeviceMode } from './get-device-mode';

export { useCustomerCommunication } from './communication-presence';

export const WidgetContextProvider: FC<PropsWithChildren<Context>> = ({
	children,
	impression,
	mode,
}) => (
	<context.Provider
		value={useMemo(() => ({ impression, mode }), [impression, mode])}
	>
		{children}
	</context.Provider>
);

export const useDisplayMode = () => useContext(context).mode || getDeviceMode();
export const useCurrentImpression = () => useContext(context).impression;
