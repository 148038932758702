import type { FC } from 'react';

export type DIEnvironment = 'prod' | 'test' | 'preview';
type SecondaryEnvironments = Exclude<DIEnvironment, 'prod'>;

let currentEnv: DIEnvironment = 'prod';

export const setDIEnv = (env: DIEnvironment) => {
	currentEnv = env;
};

export const environmentDi = <P extends {}>(
	prod: FC<P>,
	slots: Record<SecondaryEnvironments, FC<P>>,
): FC<P> => {
	const Switch = (props: P) => {
		const Slot =
			currentEnv in slots
				? slots[currentEnv as SecondaryEnvironments]
				: prod;

		return <Slot {...props} />;
	};

	return Switch;
};
