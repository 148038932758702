import type { FC, PropsWithChildren, ReactNode } from 'react';
import { useContext, useEffect, useRef, createContext } from 'react';

const autoFocusContext = createContext(true);

export const AutoFocusControl: FC<{
	allowAutoFocus: boolean;
	children: ReactNode;
}> = ({ allowAutoFocus, children }) => (
	<autoFocusContext.Provider value={allowAutoFocus}>
		{children}
	</autoFocusContext.Provider>
);

export const useAutofocusWithin = <T extends HTMLElement>(
	enabled: boolean | undefined,
) => {
	const ref = useRef<T>(null);
	const autoFocusAllowed = useContext(autoFocusContext);

	useEffect(() => {
		// TODO: can pick invisible buttons
		// use focus-lock to find out the right tabbles
		const target = ref.current?.querySelector<HTMLElement>(
			'[data-autofocus="true"]',
		);

		if (!enabled || !autoFocusAllowed || !target) {
			return;
		}

		target?.focus?.();
	}, [enabled, autoFocusAllowed]);

	return ref;
};

export const useAutoFocusRef = <T extends HTMLElement>() => {
	const ref = useRef<T>(null);
	const autoFocusAllowed = useContext(autoFocusContext);

	useEffect(() => {
		if (autoFocusAllowed && ref.current) {
			if (ref.current.contains(document.activeElement)) {
				// focus already inside
				return;
			}

			ref.current.focus?.();
		}
	}, [autoFocusAllowed]);

	return ref;
};

export const AutofocusWithin: FC<PropsWithChildren> = ({ children }) => {
	const ref = useAutofocusWithin<HTMLDivElement>(true);

	return (
		<div ref={ref} style={{ display: 'contents' }}>
			{children}
		</div>
	);
};
