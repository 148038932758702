const loadCache = new Map();
const valueCache = new Map();

export const preloadCache = <T>(importer: () => Promise<T>): Promise<any> => {
	const load = loadCache.get(importer);

	if (!load) {
		const promise = importer();

		promise.then(
			(value) => {
				valueCache.set(importer, value);
			},
			(err) => {
				console.error(err);
				valueCache.set(importer, undefined);
			},
		);

		loadCache.set(importer, promise);

		return promise;
	}

	return load;
};

export const cachedLoad = <T>(importer: () => Promise<T>): T => {
	const preload = preloadCache(importer);

	if (!valueCache.has(importer)) {
		throw preload;
	}

	return valueCache.get(importer);
};
