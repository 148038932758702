import type { ReactNode, FC } from 'react';

import * as styles from './styles';

export type ComponentType = Exclude<
	keyof JSX.IntrinsicElements,
	'button' | 'a'
>;

type ComponentProps = {
	as?: ComponentType;
	children: ReactNode;
};

export const VisuallyHidden: FC<ComponentProps> = ({
	as: Component = 'span',
	children,
}) => <Component className={styles.component}>{children}</Component>;

export const HiddenPageHeading: FC<Omit<ComponentProps, 'Component'>> = (
	props,
) => VisuallyHidden({ as: 'h1', ...props });

export const HiddenHeading: FC<
	Omit<ComponentProps, 'Component'> & { as: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' }
> = ({ as, ...props }) => VisuallyHidden({ as, ...props });
