import { css } from '@linaria/core';

export const component = css`
	display: flex;
	flex-direction: column;
	align-items: stretch;
`;

export const presentation = css`
	width: 100%;
	height: 212px;

	border-radius: 16px 16px 0 0;

	box-shadow: 0 4px 16px 0 #00000016;

	& img {
		width: 100%;
		height: 100%;

		object-fit: cover;
		object-position: center;

		border-radius: 16px 16px 0 0;
	}
`;

export const textWrapper = css`
	display: flex;
	flex-direction: column;

	padding-bottom: 40px;
	padding-inline: 24px;
`;

export const logo = css`
	display: flex;
	justify-content: center;
	margin-top: -35px;
`;

export const text = css`
	display: -webkit-box;
	margin-top: 20px;

	text-align: center;
`;

export const subText = css`
	display: -webkit-box;
	margin-top: 16px;

	text-align: center;
`;

export const buttonsGroup = css`
	display: grid;

	margin-top: 28px;
`;
