import { createContext, useContext } from 'react';

type Cb = () => void;

const scrollControlContext = createContext<Cb>(() => null);

export const ScrollControlProvider = scrollControlContext.Provider;

export const useScrollControl = () => {
	return useContext(scrollControlContext);
};
