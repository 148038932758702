import CodeOrgLogo from './assets/code-org-logo.png';
import CodeOrgXs from './assets/code-org-xs.jpg';
import CodeOrg from './assets/code-org.jpg';
import TheLifeYouCanSaveLogo from './assets/life-you-can-save-logo.png';
import TheLifeYouCanSaveXs from './assets/life-you-can-save-xs.jpg';
import TheLifeYouCanSave from './assets/life-you-can-save.jpg';
import RoomToReadLogo from './assets/room-to-read-logo.png';
import RoomToReadXs from './assets/room-to-read-xs.jpg';
import RoomToRead from './assets/room-to-read.jpg';

export const CharityList = [
	{
		name: 'The Life You Can Save',
		squareLogo: TheLifeYouCanSaveLogo,
		rectLogo: '',
		imageXs: TheLifeYouCanSaveXs,
		image: TheLifeYouCanSave,
		purpose: 'Help end extreme poverty',
		exitText: 'We’re working together to end extreme poverty',
		logoBg: '#fff',
		logoStroke: '#EAECF0',
	},
	{
		name: 'Room to Read',
		squareLogo: RoomToReadLogo,
		rectLogo: '',
		imageXs: RoomToReadXs,
		image: RoomToRead,
		purpose: 'Support education & literacy',
		exitText: 'World change starts with educated children',
		logoBg: 'yellow',
	},
	{
		name: 'Code.org',
		squareLogo: CodeOrgLogo,
		rectLogo: '',
		imageXs: CodeOrgXs,
		image: CodeOrg,
		purpose: 'Expand computer science access',
		exitText: 'Every child deserves the chance to learn computer science',
		logoBg: '#fff',
		logoStroke: '#EAECF0',
	},
];
