import { useFeatureFlag } from '@smile/experimentation';
import { useCallback, useState } from 'react';

import { useQueue } from './use-queue';

export const useFlowSteps = (
	initialStep: number,
	{
		onFinish,
		stepLimit,
	}: {
		onFinish(): void;
		stepLimit: number;
	},
) => {
	const [stepEnterTime, setStepEnterTime] = useState(Date.now());
	const [step, setRealStep] = useState(initialStep);
	const [maximumViewedStep, setMaximumViewedStep] = useState(
		useFeatureFlag('display-all-steps') ? stepLimit : initialStep,
	);

	const [steps, pushStep] = useQueue(2, [step]);

	const setStep = useCallback(
		(step: number) => {
			try {
				setRealStep(step);
				setStepEnterTime(Date.now());
				setMaximumViewedStep((oldStep) => Math.max(step, oldStep));
				pushStep(step);
			} catch (e) {
				console.error(e);
			}
		}, // TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const onSetSlide = useCallback(
		(newStep: number) => {
			setStep(newStep);
		}, // TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const advance = useCallback(
		(jump = 1) => {
			const nextStep = step + jump;

			if (nextStep >= stepLimit) {
				onFinish();
			} else {
				setStep(nextStep);
			}
		},
		// TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[step],
	);

	const [themeControl, setThemeControl] = useState<'normal' | 'flip'>(
		'normal',
	);

	return {
		stepEnterTime,
		maximumViewedStep,
		steps,
		step,
		stepLimit,

		onSetSlide,
		advance,
		setStep,

		themeControl,
		setThemeControl,
	} as const;
};
