import type { ImpressionDevice } from '@thanks/impression-type';
import { BREAKPOINTS } from '@theway/helpers-css/breakpoints';

export const getDeviceMode = (): ImpressionDevice => {
	if (typeof window === 'undefined') {
		return 'desktop';
	}

	if (window.innerWidth >= BREAKPOINTS.sm) {
		return 'desktop';
	}

	return 'mobile';
};
