export const concatSearchPart = (
	urlPart: string,
	searchPart: string,
): string => {
	if (urlPart.includes('?')) {
		return `${urlPart}&${searchPart}`;
	}

	return `${urlPart}?${searchPart}`;
};
