import { atom, useAtom } from 'jotai';
import { useEffect, useRef } from 'react';

/**
 * creates a "stacked" controller to track the "top most" invocation
 */
export const createStackedController = () => {
	const stackedControl = atom<any[]>([]);
	let counter = 0;

	/**
	 * returns if given invocation is the topmost of enabled
	 */
	const useStackedControl = (enabled: boolean, name = '') => {
		const symbol = useRef({
			enabled,
			name,
			counter: 0,
		});
		symbol.current.enabled = enabled;

		// use "render time" counter to capture the right order of mounting
		// as "effects" are executed in the "reverse" order
		if (!symbol.current.counter) {
			// sideEffect
			symbol.current.counter = ++counter;
		}

		const [list, setList] = useAtom(stackedControl);

		useEffect(() => {
			setList((atoms) =>
				[...atoms, symbol].sort(
					(a, b) => a.current.counter - b.current.counter,
				),
			);

			return () => {
				setList((atoms) => atoms.filter((a) => a !== symbol));
			};
		}, [enabled, setList]);

		const activeStack = list.filter((x) => x.current.enabled);

		// fixme: use at(-1) when it will be supported by all browsers
		return activeStack[activeStack.length - 1] === symbol;
	};

	return useStackedControl;
};
