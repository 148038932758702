import type { StepPreloader } from '../../components/preload';
import { preloadImage, preloadMediaImage } from '../../utils/preload-image';

import { slotRender } from './meleagris-gallopavo-broker';

export const preloadAll = () => slotRender.preloadAll();

export const preparePreset = () => null;

export const preload: StepPreloader = (step, impression) => {
	if (step === 0) {
		const { brand } = impression;

		return Promise.all([
			preloadImage(brand.logo),
			slotRender.preload(impression.slots[0].type),
			'mg' in brand ? preloadMediaImage(brand.mg) : undefined,
		]).then(
			() => Promise.resolve(),
			(e) => {
				// captureException(e);
				console.error(e);
			},
		);
	}

	const slot = impression.slots[step - 1];
	const slotData = slot?.data;

	if (!slotData) {
		return Promise.resolve();
	}

	return Promise.all([
		'img' in slotData ? preloadImage(slotData.img.active) : undefined,
		'mg' in slotData ? preloadMediaImage(slotData.mg) : undefined,
		slotRender.preload(slot.type),
	]).then(() => Promise.resolve());
};
