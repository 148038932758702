import { css } from '@linaria/core';
import { font } from '@smile/tokens/font';
import { palette } from '@smile/tokens/palette';

export const component = css`
	position: fixed;
	inset-inline: 0;
	bottom: 0;

	height: 600px;

	border-radius: 16px 16px 0 0;

	box-shadow: 0 4px 50px 5px #00000040;

	background-color: #fff;

	transition:
		transform 0.5s ease-in-out,
		opacity 0.5s ease-in-out;
`;

export const swapped = css`
	transform: translateY(100%);
	opacity: 0;
`;

export const wrapper = css`
	height: 100%;

	border-radius: inherit;

	overflow: hidden;
`;

export const swap = css`
	position: absolute;
	top: 12px;
	left: 50%;
	z-index: 10;

	display: block;
	width: 40px;
	height: 4px;

	border-radius: 4px;
	background-color: rgba(248, 248, 248, 0.9);

	transform: translateX(-50%);

	&::before {
		position: absolute;
		inset-block: -8px;
		inset-inline: -24px;
		content: '';
	}
`;

export const header = css`
	position: absolute;
	z-index: 1;
	inset-inline: 24px;
	top: 28px;

	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 4px;

	width: calc(100% - 48px);

	font-family: ${font.family.primary};
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;

	border-radius: 9999px;

	background-color: rgba(248, 248, 248, 0.9);
`;

export const hidden = css`
	background-color: transparent;

	& button {
		background-color: rgba(248, 248, 248, 0.7);
	}
`;

export const closeButton = css`
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	width: 30px;
	height: 30px;
	margin-left: auto;

	border-radius: 50%;

	& svg {
		width: 20px;
		height: auto;

		color: #002140;
	}

	&::before {
		position: absolute;
		inset: -5px;
	}
`;

export const content = css`
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 20px;

	height: 100%;

	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */

	//scroll-snap-type: both mandatory;
	overflow: auto;

	overscroll-behavior: contain;

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
`;

export const down = css`
	position: absolute;
	bottom: 16px;
	left: 50%;
	z-index: 10;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	width: 40px;
	height: 40px;

	background-color: white;

	border-radius: 50%;

	transform: translateX(-50%);

	box-shadow: 0 0 0 0 rgba(255, 212, 241, 1);
	animation: pulse 1.2s infinite;

	& div {
		width: 20px;
		height: 20px;
	}

	& svg {
		width: 100%;
		height: auto;

		color: ${palette.baseBlue[500]};
	}

	@keyframes pulse {
		0% {
			box-shadow: 0 0 0 1px rgba(255, 212, 241, 1);
		}
		70% {
			box-shadow: 0 0 0 16px rgba(255, 212, 241, 0);
		}
		100% {
			box-shadow: 0 0 0 1px rgba(255, 212, 241, 0);
		}
	}
`;

export const pagination = css`
	position: absolute;
	bottom: 0;
	inset-inline: 0;
	z-index: 10;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	column-gap: 4px;

	height: 48px;

	background: linear-gradient(
		to top,
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0.3)
	);

	pointer-events: none;
`;

export const item = css`
	aspect-ratio: 1/1;
	width: 12px;

	border-radius: 50%;

	background-color: rgba(255, 168, 225, 0.3);
`;

export const active = css`
	background-color: rgba(255, 168, 225, 1);
`;
