/**
 * picks random from array
 */
export const pickRandom = <T>(data: readonly T[]): T =>
	data[Math.floor(Math.random() * data.length)];

/**
 * picks random from an object
 * return object key and value
 */
export const conductControlledExperiment = <
	const P extends string,
	const T extends string,
>(
	experimentName: P,
	experiments: ReadonlyArray<T>,
) => {
	return pickRandom([
		...withDefaultBaseline(experimentName),
		...namedExperiment(experimentName, experiments),
	]);
};

/**
 * returns "default" baseline measured in two buckets - `baseline` and `control`
 * @param name
 */
export const withDefaultBaseline = <T extends string>(name: T) =>
	[[`${name}:control`, 'default']] as const;

export const namedExperiment = <const T extends string, const K extends string>(
	name: T,
	experiments: readonly K[],
): ReadonlyArray<[`${T}:${K}`, K]> =>
	experiments.map(
		(experiment) => [`${name}:${experiment}`, experiment] as const,
	);

export type EXPERIMENT = 'experiment' | 'control' | 'not-enrolled';

/**
 * Rolls dice
 * @param percentage
 * @returns if dice roll is less than percentage (so you are in luck)
 */
export const rollDice = (percentage: number): boolean =>
	Math.random() * 100 < percentage;

/**
 * Runs controlled experiment
 * @param percentage 0-100
 * @returns
 *  - experiment: experiment cohort
 *  - control: control comparison cohort
 *  - not-enrolled: rest of population
 */
export const experimentRollout = (percentage: number): EXPERIMENT => {
	const randomValue = Math.random() * 100;

	if (randomValue < percentage) {
		return 'experiment';
	} else if (randomValue < percentage * 2) {
		return 'control';
	} else {
		return 'not-enrolled';
	}
};

export const isExperimentalCohort = (cohort: EXPERIMENT): boolean =>
	['experiment', 'control'].includes(cohort);
