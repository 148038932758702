import type {
	ImpressionDevice,
	ImpressionSequence,
} from '@thanks/impression-type';

import { createContext } from 'react';

export type Context = {
	impression: ImpressionSequence;
	mode: ImpressionDevice;
};

export const context = createContext<Context>({
	impression: undefined as any,
	mode: undefined as any,
});
