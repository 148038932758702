export const palette = {
	powerHouse: {
		25: '#FFF8FD',
		50: '#FFEEF9',
		100: '#FFE1F5',
		200: '#FFD4F0',
		300: '#FFC8EC',
		400: '#FFBAE8',
		500: '#FFA8E1',
		600: '#F973D0',
		700: '#FE5EC7',
		800: '#FF14AE',
		900: '#C70183',
	},
	gray: {
		25: '#FCFCFD',
		50: '#F9FAFB',
		100: '#F2F4F7',
		200: '#EAECF0',
		300: '#D0D5DD',
		400: '#98A2B3',
		500: '#667085',
		600: '#475467',
		700: '#344054',
		800: '#1D2939',
		900: '#101828',
	},
	salmonParty: {
		500: '#FFBEA9',
	},
	richPurple: {
		500: '#400042',
	},
	babyBlue: {
		500: '#A7C5FF',
	},
	burgundy: {
		500: '#450000',
	},
	greenMachine: {
		500: '#313F00',
	},
	cyanBurn: {
		500: '#92FFFF',
	},
	ohYellow: {
		500: '#FFF7A2',
	},
	forestCake: {
		500: '#003F39',
	},
	baseBlue: {
		500: '#002140',
	},
	base: {
		white: '#FFFFFF',
		black: '#000000',
	},
};
