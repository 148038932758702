import { css } from '@linaria/core';
import { font } from '@smile/tokens/font';
import { palette } from '@smile/tokens/palette';

export const lightTheme = css`
	//primary
	--primary-color: var(
		--custom-primary-action-color,
		${palette.baseBlue[500]}
	);

	//secondary
	--secondary-color: var(--text-primary, ${palette.baseBlue[500]});
	--secondary-background: rgba(255, 255, 255, 0.7);
	--secondary-background-hovered: ${palette.gray[50]};
	--secondary-background-focused: ${palette.base.white};
	--secondary-background-disabled: ${palette.gray[300]};

	[data-theme='zip'] & {
		--primary-color: #fffffa;
		--custom-primary-action-bg: #411361;
		--primary-background-hovered: rgb(89, 54, 116);
		--primary-background-focused: #411361;
		--primary-background-disabled: #411361;
		--primary-color-disabled: #fffffa;

		--secondary-color: #411361;
		--secondary-background: rgba(255, 255, 250, 1);
		--secondary-background-hovered: rgba(101, 66, 190, 0.04);
		--secondary-background-focused: rgba(255, 255, 250, 1);
		--secondary-background-disabled: rgba(255, 255, 250, 1);

		--focus-ring: var(
			--focus-ring-disabled,
			0 0 0 4px rgba(131, 100, 191, 0.15)
		);
	}

	[data-theme='zip-us'] & {
		--primary-color: #fffffa;
	}

	[data-theme='humanitix'] & {
		--primary-color: #000000;
	}

	[data-theme='ebay'] & {
		--primary-color: #fff;
	}

	[data-theme='try-booking'] & {
		--primary-color: #ffffff;
	}

	[data-theme='sezzle'] & {
		--primary-color: #ffffff;
	}
`;

export const darkTheme = css`
	--primary-color: var(
		--custom-secondary-action-color,
		${palette.baseBlue[500]}
	);
	--primary-background: var(
		--custom-secondary-action-bg,
		${palette.powerHouse[500]}
	);

	//secondary
	--secondary-color: var(--text-primary, ${palette.base.white});
	--secondary-background: rgba(255, 255, 255, 0.7);
	--secondary-background-hovered: ${palette.gray[500]};
	--secondary-background-focused: ${palette.gray[500]};
	--secondary-background-disabled: ${palette.gray[300]};

	[data-theme='zip'] & {
		--primary-color: #fffffa;
		--custom-secondary-action-bg: #6542be;
		--primary-background-hovered: rgba(101, 66, 190, 0.8);
		--primary-background-focused: #6542be;
		--primary-background-disabled: #6542be;
		--primary-color-disabled: #fffffa;

		//secondary
		--secondary-color: #fffffa;
		--secondary-background: transparent;
		--secondary-background-hovered: rgba(101, 66, 190, 0.2);
		--secondary-background-focused: transparent;
		--secondary-background-disabled: transparent;

		--focus-ring: var(
			--focus-ring-disabled,
			0 0 0 4px rgba(255, 255, 250, 0.2)
		);
	}

	[data-theme='zip-us'] & {
		--primary-color: #fffffa;
	}

	[data-theme='humanitix'] & {
		--primary-color: #fff;
	}

	[data-theme='ebay'] & {
		--primary-color: #fff;
	}

	[data-theme='try-booking'] & {
		--primary-color: #03517e;
	}

	[data-theme='sezzle'] & {
		--primary-color: #382757;
	}
`;

export const contrastTheme = css`
	//primary
	--primary-color: var(
		--custom-tertiary-action-color,
		${palette.powerHouse[500]}
	);

	//secondary
	--secondary-color: var(--text-primary, ${palette.baseBlue[500]});
	--secondary-background: transparent;
	--secondary-background-hovered: ${palette.gray[50]};
	--secondary-background-focused: ${palette.base.white};
	--secondary-background-disabled: ${palette.gray[300]};
`;

export const component = css`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 8px;

	box-sizing: border-box;

	font-family: ${font.family.primary};
	font-weight: ${font.weight.medium};
	font-size: 18px;
	line-height: 24px;

	border-radius: var(--custom-action-border-radius, 9999px);
	border: none;

	transition-timing-function: linear;
	transition-duration: 200ms;
	transition-property: background-color, color, filter, box-shadow;

	[data-theme='zip'] & {
		border-radius: 8px;
	}

	[data-theme='zip-us'] & {
		border-radius: 8px;
	}

	[data-theme='humanitix'] & {
		border-radius: 4px;
	}

	[data-theme='try-booking'] & {
		border-radius: 4px;
	}

	& svg {
		aspect-ratio: 1/1;
		width: 20px;

		color: inherit;
	}

	&:disabled {
		//generic disabled styles
		opacity: 0.4;
	}
`;

export const primary = css`
	color: var(--primary-color);

	background-color: var(--primary-action-background);

	&:hover {
		//generic hover styles
		background-blend-mode: multiply;
		filter: brightness(96%);

		[data-theme='zip'] & {
			background-color: var(--primary-background-hovered);
			background-blend-mode: normal;
			filter: none;
		}
	}

	[data-focus-visible] &:focus-visible {
		//generic focus styles
		background-blend-mode: normal;
		filter: none;

		outline-color: var(--outline-color);
		outline-offset: 2px;
		outline-style: solid;
		outline-width: 3px;

		[data-theme='zip'] & {
			background-color: var(--primary-background-focused);
			outline: none;

			box-shadow: var(--focus-ring);
		}
	}

	&:disabled {
		//generic disable styles
		filter: grayscale(1);

		[data-theme='zip'] & {
			filter: grayscale(1);
			color: var(--primary-color-disabled);
			background-color: var(--primary-background-disabled);
		}
	}
`;

export const secondary = css`
	color: var(--secondary-color);

	background-color: var(--secondary-background);

	&:hover {
		background-color: var(--secondary-background-hovered);
	}

	[data-focus-visible] &:focus-visible {
		background-color: var(--secondary-background-hovered);
		background-blend-mode: normal;
		filter: none;

		outline-color: var(--outline-color);
		outline-offset: 2px;
		outline-style: solid;
		outline-width: 3px;

		[data-theme='zip'] & {
			outline: none;
			box-shadow: var(--focus-ring);
		}
	}

	&:disabled {
		background-color: var(--secondary-background-disabled);
	}
`;

export const small = css`
	height: 30px;
	padding-inline: 14px;
`;

export const large = css`
	height: 48px;
	padding-inline: 20px;
`;

export const fullWidth = css`
	width: 100%;
`;

export const icon = css`
	aspect-ratio: 1/1;
	padding: 0;

	border-radius: 50% !important;
`;

export const iconBefore = css``;

export const iconAfter = css``;
