import { pickRandom } from '@smile/experiments';
import type { ImpressionSequence } from '@thanks/impression-type';
import {
	EventContainer,
	useGlobalAnalyticsAttributes,
} from '@theway/analytics';
import { fromMarkAst } from '@theway/markdown-ui';
import { Layout, OfferLayout } from '@zip/widget';
import type { FC } from 'react';
import { Fragment, Suspense, useMemo } from 'react';

import { analytics } from '../../analytics';
import { useStepPreload } from '../../components/preload';
import { useFlowSteps } from '../../components/use-flow';
import {
	getAdTrackingAttributes,
	useExposureTracking,
} from '../../utils/ad-analytics';
import {
	DesktopWrapper,
	NativePositionMobileWrapper,
} from '../../views/layout';

import { useAdProps } from '../components/component-adoption';

import { useStepImages } from '../components/hooks';
import { useSlots } from '../components/slots';
import { renderStepX1 } from '../components/step-renderer-x1';

import { SuspendedRender } from '../components/utils';

import { preload as preloadStep } from './load-control';
import { slotRender } from './zip-broker';

const WRAPPERS = {
	desktop: DesktopWrapper,
	mobile: NativePositionMobileWrapper,
} as const;

export const ZipApp: FC<{
	mode: 'mobile' | 'desktop';
	impression: ImpressionSequence;
	offsetTop: number | undefined;
	onClose(): void;
	initialStep?: number;
}> = ({ mode, impression, onClose, offsetTop, initialStep = 0 }) => {
	const Wrapper = WRAPPERS[mode];

	const stepImages = useStepImages(impression);
	const slots = useSlots(impression);

	const {
		maximumViewedStep,
		onSetSlide,
		advance,
		stepEnterTime,
		step,
		stepLimit,
	} = useFlowSteps(initialStep, {
		onFinish: onClose,
		stepLimit: slots.length,
	});

	const stepNavigationProps = {
		slides: stepImages,
		activeSlide: step,
		maximumSlide: Math.max(step, maximumViewedStep),
		onSetSlide,
	} as const;

	const renderStepLayout = (renderedStep: number) => {
		const slide = slots[renderedStep];
		const text =
			'data' in slide
				? 'text' in slide.data
					? fromMarkAst(slide.data.text)
					: '...'
				: '...';

		return <OfferLayout text={text} subtext="..." disclaimer={undefined} />;
	};

	const rendererStep = (renderedStep: number) => {
		return renderStepX1(
			renderedStep,
			{
				maximumViewedStep,
				stepLimit,
				slots,
				stepImages,
				onSetSlide,
				stepEnterTime,
				impression,
				advance,
				onClose,
				slotRender,
			},
			{
				Splash: () => <></>,
			},
		);
	};

	const onLayoutClose = () => {
		const slot = slots[step];

		analytics.ad.skip(slot?.data.experienceId || 'welcome', {
			...getAdTrackingAttributes(slot, step, impression),
			timeVisible: Date.now() - stepEnterTime,
		});

		onClose();
	};

	const slot = slots[step];

	const slotImage = {
		url: (slot.data.mg || impression.brand.mg)?.image_desktop || '',
		xsUrl: (slot.data.mg || impression.brand.mg)?.image_mobile || '',
	};

	const adProps = useAdProps(slot);

	const backgroundColorOverride = useMemo(
		() => {
			const config = impression.brand.background;

			if (!config) {
				return undefined;
			}

			return pickRandom(config);
		}, // TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useStepPreload(impression, preloadStep, step, slots.length);

	useGlobalAnalyticsAttributes(['ui', 'event'], {
		position: step,
		type: slot?.type,
		backgroundColor: backgroundColorOverride,
		partner: slot?.data.partnerName,
		get timeVisible() {
			return Date.now() - stepEnterTime;
		},
	});

	useExposureTracking(step, slots[step], impression);

	return (
		<Wrapper offsetTop={offsetTop}>
			<EventContainer name="step" attribute={step}>
				<Layout
					theme={'light'}
					statusText={
						impression.statusOverride[step] || impression.statusText
					}
					onClose={onLayoutClose}
					currentImage={slotImage.xsUrl}
					previousImage={slotImage.xsUrl}
					terms={
						slot && 'termsText' in slot.data
							? {
									text: slot.data.termsText,
									logo: slot.data.img.active,
									logoBg: slot.data.img.bg,
									logoStroke: slot.data.img.stroke,
								}
							: undefined
					}
					badge={
						slot && 'badge' in slot.data
							? fromMarkAst(slot.data.badge)
							: undefined
					}
					{...stepNavigationProps}
					{...adProps}
				>
					<Fragment key={step}>
						<Suspense fallback={renderStepLayout(step)}>
							<SuspendedRender>
								{() => rendererStep(step)}
							</SuspendedRender>
						</Suspense>
					</Fragment>
				</Layout>
			</EventContainer>
		</Wrapper>
	);
};
