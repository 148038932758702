import type { SlideType } from '@smile/widget-common/types';
import type { ImpressionSequence } from '@thanks/impression-type';
import { useMemo } from 'react';

export const useStepImages = (impression: ImpressionSequence) => {
	return useMemo(
		() =>
			impression.slots
				.map<SlideType | undefined>((offer) =>
					'img' in offer.data
						? {
								img: offer.data.img.active,
								bg: offer.data.img.bg,
								stroke:
									offer.data.img.stroke || offer.data.img.bg,
							}
						: undefined,
				)
				.filter((x): x is SlideType => Boolean(x)),
		[impression.slots],
	);
};
