import { IconButton } from '@smile/ds/actions';

import { ShareIcon } from '@smile/ds/icons/ShareIcon';
import type { FC } from 'react';

import * as styles from './styles';
import type { ShareButtonProps } from './types';

export const ShareButton: FC<ShareButtonProps> = ({ onShare }) => {
	return onShare ? (
		<IconButton
			className={styles.share}
			onClick={onShare}
			icon={<ShareIcon />}
		>
			Share
		</IconButton>
	) : null;
};
