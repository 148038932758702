import { clsx } from 'clsx';
import type { FC } from 'react';

import { logo } from './const';

import * as styles from './styles';

type LogoProps = {
	className?: string;
	theme: 'dark' | 'light';
	width: number | string;
};

export const Logo: FC<LogoProps> = ({ className, theme, width }) => {
	const isStringWidth = typeof width === 'string';

	return (
		<div
			className={clsx(className, styles.component, styles[theme])}
			style={{
				width: isStringWidth ? width : `${width}px`,
				height: isStringWidth ? width : `${width}px`,
			}}
			dangerouslySetInnerHTML={{ __html: logo }}
		/>
	);
};
