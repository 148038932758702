import { css } from '@linaria/core';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const component = css`
	display: flex;
	flex-direction: column;

	height: 100%;

	padding-inline: 24px;
	padding-top: 24px;

	overflow: hidden;

	${container('content', 'width < 358px')} {
		padding-inline: 20px;
	}
`;

export const subtext = css`
	margin-top: 12px;
`;

export const disclaimer = css`
	margin-top: 2px;

	& sup {
		margin-top: -8px;
	}
`;

export const actions = css`
	flex-grow: 1;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-end;
	row-gap: 8px;

	margin-top: 16px;
`;
