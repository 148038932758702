import type { FC, PropsWithChildren } from 'react';

export const analyticsContainerAttribution = (
	sectionName: string,
	sectionId: string | number | undefined,
) => ({
	'data-action-section': sectionName,
	'data-action-section-id': sectionId,
});
export const EventContainer: FC<
	PropsWithChildren<{ name: string; attribute?: string | number }>
> = ({ children, name, attribute }) => (
	<div
		style={{ display: 'contents' }}
		{...analyticsContainerAttribution(name, attribute)}
	>
		{children}
	</div>
);
