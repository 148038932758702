import { css } from '@linaria/core';
import { font } from '@smile/tokens/font';
import { palette } from '@smile/tokens/palette';
import { from, Media, until } from '@theway/helpers-css/breakpoints';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const component = css`
	display: flex;
	flex-direction: column;

	height: 100%;

	padding-inline: 24px;
	padding-block: 24px;
	padding-bottom: 2px;

	/* limit offer dimensions */
	overflow: hidden;

	${container('content', 'width < 358px')} {
		padding-inline: 20px;
	}

	${from(Media.SM)} {
		width: 422px;

		padding-inline: 40px;
		padding-top: 16px;
		padding-bottom: 5px;
		margin-bottom: -5px;
	}
`;

export const dualComponent = css`
	display: flex;
	flex-direction: column;

	height: 100%;

	padding-inline: 24px;
	padding-top: 24px;

	/* limit offer dimensions */
	overflow: hidden;

	${container('content', 'width < 358px')} {
		padding-inline: 20px;
	}

	${from(Media.SM)} {
		width: 422px;

		padding-inline: 40px;
		padding-top: 24px;
		padding-bottom: 6px;
	}
`;

export const subtext = css`
	margin-top: 12px;
`;

export const gambling = css`
	margin-top: 12px;
`;

export const disclaimer = css`
	margin-top: 2px;

	& sup {
		margin-top: -8px;
	}
`;

export const actions = css`
	flex-grow: 1;

	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-end;
	row-gap: 16px;

	margin-top: 16px;
`;

export const mobile = css`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 16px;

	${from(Media.SM)} {
		display: none;
	}
`;

export const desktop = css`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 16px;

	${until(Media.SM)} {
		display: none;
	}
`;

export const row = css`
	display: flex;
	flex-direction: row;
`;

export const forward = css`
	${from(Media.SM)} {
		width: 100%;
	}
`;

export const reveal = css`
	position: absolute;
	inset-block: -3px;
	inset-inline: -3px;

	height: calc(100% + 6px);

	color: var(--custom-primary-action-color, ${palette.baseBlue[500]});

	border-radius: var(--custom-action-border-radius, 30px);

	overflow: hidden;

	[data-theme='zip'] & {
		color: #fffffa;

		border-radius: 8px;
	}

	[data-theme='zip-us'] & {
		color: #fffffa;

		border-radius: 8px;
	}

	[data-theme='humanitix'] & {
		color: #000000;

		border-radius: 4px;
	}

	[data-theme='ebay'] & {
		color: #fff;
	}

	[data-theme='try-booking'] & {
		color: #fff;

		border-radius: 4px;
	}

	[data-theme='sezzle'] & {
		color: #fff;
	}
`;

export const revealText = css`
	position: absolute;
	inset: 0;
	z-index: 1;

	display: inline-block;
	height: 100%;

	overflow: hidden;

	transition-property: right;
	transition-timing-function: linear;
	transition-duration: 150ms;

	& span {
		position: relative;

		display: flex;
		justify-content: center;
		align-items: center;

		height: 100%;
	}
`;

export const bg = css`
	position: absolute;
	inset: 0;
	right: 0;

	display: block;
	height: 100%;

	background-color: var(--primary-action-background);

	transition-property: right;
	transition-timing-function: linear;
	transition-duration: 150ms;
`;

export const revealCode = css`
	position: relative;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	column-gap: 8px;

	height: 48px;

	font-family: ${font.family.primary};
	font-weight: ${font.weight.medium};
	font-size: 18px;
	line-height: 24px;
	color: var(--text-primary, ${palette.baseBlue[500]});

	border-radius: var(--custom-action-border-radius, 30px);
	border: 3px dashed var(--primary-action-background);

	background-color: ${palette.gray[100]};

	&:hover .${revealText} {
		right: 36px;
	}

	&:hover .${bg} {
		right: 36px;
	}

	[data-focus-visible] &:focus-visible {
		outline-color: var(--outline-color);
		outline-offset: 2px;
		outline-style: solid;
		outline-width: 3px;
	}

	[data-focus-visible] &:focus-visible .${revealText} {
		right: 36px;
	}

	[data-focus-visible] &:focus-visible .${bg} {
		right: 36px;
	}

	[data-theme='zip'] & {
		border-radius: 8px;
	}

	[data-theme='zip-us'] & {
		border-radius: 8px;
	}

	[data-theme='humanitix'] & {
		border-radius: 4px;
	}

	[data-theme='try-booking'] & {
		border-radius: 4px;
	}
`;

export const revealed = css`
	& .${revealText} {
		right: 100%;
	}

	& .${bg} {
		right: 100%;
	}

	&:hover .${revealText} {
		right: 100% !important;
	}

	&:hover .${bg} {
		right: 100% !important;
	}
`;

export const dropdown = css`
	--emphasize: var(--custom-accent-color, ${palette.cyanBurn[500]});
	--copied: var(--custom-primary-color, ${palette.baseBlue[500]});

	position: relative;

	[data-theme='zip'] & {
		--emphasize: #dcf995;
		--copied: #1a0826;
	}

	[data-theme='zip-us'] & {
		--emphasize: #dcf995;
		--copied: #1a0826;
	}

	[data-theme='humanitix'] & {
		--emphasize: rgb(168, 255, 194);
		--copied: #030303;
	}

	[data-theme='ebay'] & {
		--emphasize: ${palette.cyanBurn[500]};
		--copied: #191919;
	}
`;

export const hint = css`
	position: absolute;
	top: -10px;
	left: 50%;
	z-index: 1;

	transform: translateX(-50%);

	transition-timing-function: linear;
	transition-property: opacity;
	transition-duration: 75ms;
`;

export const shop = css`
	overflow: hidden;

	transition-property: width, height;
	transition-timing-function: linear;
	transition-duration: 150ms;

	${until(Media.SM)} {
		height: 48px;
		margin: 0;

		transform-origin: bottom;
	}

	${from(Media.SM)} {
		flex-shrink: 0;

		width: 163px;
		margin-right: 16px;

		transform-origin: left;
	}

	& a {
		${until(Media.SM)} {
			width: 100%;
			height: 48px;
		}

		${from(Media.SM)} {
			width: 100%;
			height: 100%;
		}
	}
`;

export const shopNowMobile = css`
	justify-content: center;
	gap: 8px;
`;

export const shopHidden = css`
	${until(Media.SM)} {
		height: 0;
		margin-bottom: -16px;
	}

	${from(Media.SM)} {
		width: 0;
		margin-right: 0;
	}
`;

export const next = css`
	padding-inline: 12px !important;
	white-space: nowrap;

	${from(Media.SM)} {
		flex-grow: 1;
	}
`;

export const blur = css`
	display: block;
	width: 100%;
	height: 100%;

	background-color: rgba(255, 255, 255, 0.3);

	//backdrop-filter: blur(0px) brightness(1.2);;
`;

export const checkout = css`
	position: absolute;
	inset-block: 0;
	left: 0;
	right: -16px;

	display: block;
	width: calc(100% + 16px);
	height: 100%;

	background-color: ${palette.gray[100]};
`;

export const customButton = css`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 8px;

	height: 48px;

	box-sizing: border-box;

	font-family: ${font.family.primary};
	font-weight: ${font.weight.medium};
	font-size: 18px;
	line-height: 24px;

	border-radius: 9999px;
	border: none;

	transition-timing-function: linear;
	transition-duration: 200ms;
	transition-property: all;

	[data-theme='zip'] & {
		border-radius: 8px;
	}

	[data-theme='zip-us'] & {
		border-radius: 8px;
	}

	[data-theme='humanitix'] & {
		border-radius: 4px;
	}

	& svg {
		aspect-ratio: 1/1;
		width: 20px;

		color: inherit;
	}
`;
