import { analyticsAttribution } from '@theway/analytics';
import classNames from 'clsx';
import type { ReactNode, FC, MouseEvent } from 'react';

import type { VariantType, SizeType, ColorType } from './types';
import { useActionProperty } from './useActionProperty';

type CommonActionProps = {
	className?: string;
	children: ReactNode;
	disabled?: boolean;

	actionName?: string;
	autoFocus?: boolean;
	'aria-describedby'?: string;

	onClick?(e: MouseEvent): void;
};

type CommonStyleProps = {
	variant: VariantType;
	color: ColorType;
	size?: SizeType;
	fullWidth?: boolean;
	iconAfter?: ReactNode;
	iconBefore?: ReactNode;
	icon?: ReactNode;
	underlined?: boolean;
};

type ButtonProps = { submit?: boolean };

export const Button: FC<CommonStyleProps & CommonActionProps & ButtonProps> = ({
	className: elementClassName,
	size = 'large',
	onClick,
	submit,
	disabled = false,
	actionName,
	autoFocus,
	...props
}) => {
	const { className, content } = useActionProperty(
		props.children,
		props.variant,
		props.color,
		size,
		props.icon,
		props.iconBefore,
		props.iconAfter,
		props.fullWidth,
		props.underlined,
	);

	return (
		<button
			className={classNames(className, elementClassName)}
			onClick={onClick ? onClick : () => null}
			disabled={disabled}
			type={submit ? 'submit' : 'button'}
			{...analyticsAttribution(actionName)}
			aria-describedby={props['aria-describedby']}
			data-autofocus={autoFocus}
		>
			{content}
		</button>
	);
};

type LinkProps = {
	href: string;
	newTab?: boolean;
};

export const Link: FC<CommonStyleProps & CommonActionProps & LinkProps> = ({
	className: elementClassName,
	size = 'large',
	href,
	newTab,
	actionName,
	onClick,
	autoFocus,
	...props
}) => {
	const { className, content } = useActionProperty(
		props.children,
		props.variant,
		props.color,
		size,
		props.icon,
		props.iconBefore,
		props.iconAfter,
		props.fullWidth,
		props.underlined,
	);

	const settings = newTab && {
		target: '_blank',
		rel: 'noopener, noreferrer',
	};

	return (
		<a
			className={classNames(className, elementClassName)}
			href={href}
			{...settings}
			{...analyticsAttribution(actionName)}
			data-autofocus={autoFocus}
			onClick={onClick}
			aria-describedby={props['aria-describedby']}
		>
			{content}
		</a>
	);
};

type ButtonType = Omit<
	CommonStyleProps & CommonActionProps,
	'variant' | 'color' | 'size'
> &
	ButtonProps;

export const PrimaryButton: FC<ButtonType> = (props) => {
	return Button({
		variant: 'contained',
		color: 'primary',
		size: 'large',
		...props,
	});
};

export const SecondaryButton: FC<ButtonType> = (props) => {
	return Button({
		variant: 'contained',
		color: 'secondary',
		size: 'large',
		...props,
	});
};

export const InlineButton: FC<Omit<ButtonType, 'color'>> = (props) => {
	return Button({
		variant: 'inline',
		color: 'primary',
		...props,
	});
};

type LinkType = Omit<
	CommonStyleProps & CommonActionProps,
	'variant' | 'color' | 'size'
> &
	LinkProps;

export const PrimaryLink: FC<LinkType> = (props) => {
	return Link({
		variant: 'contained',
		color: 'primary',
		size: 'large',
		...props,
	});
};

export const SecondaryLink: FC<LinkType> = (props) => {
	return Link({
		variant: 'contained',
		color: 'secondary',
		size: 'large',
		...props,
	});
};

export const InlineLink: FC<Omit<LinkType, 'color'>> = (props) => {
	return Link({
		variant: 'inline',
		color: 'primary',
		...props,
	});
};

type IconButtonType = Omit<
	CommonStyleProps & CommonActionProps,
	'variant' | 'color' | 'size' | 'iconBefore' | 'iconAfter'
> &
	ButtonProps;

export const IconButton: FC<IconButtonType> = (props) => {
	return Button({
		variant: 'contained',
		color: 'secondary',
		size: 'small',
		...props,
	});
};

type IconLinkType = Omit<
	CommonStyleProps & CommonActionProps,
	'variant' | 'color' | 'size' | 'iconBefore' | 'iconAfter'
> &
	LinkProps;

export const IconLink: FC<IconLinkType> = (props) => {
	return Link({
		variant: 'contained',
		color: 'secondary',
		size: 'small',
		...props,
	});
};
