import { clsx } from 'clsx';

import type { FC } from 'react';

import { ImageByUrl } from '../image-by-url';

import * as styles from './styles';
import type { PresentationProps } from './types';

export const Presentation: FC<PresentationProps & { hidden?: boolean }> = ({
	currentImage,
	previousImage,
	hidden,
}) => {
	return (
		<div style={{ position: 'absolute', inset: 0 }}>
			<ImageByUrl
				className={clsx(styles.slide, hidden && styles.hidden)}
				image={{
					images: [
						{
							url: currentImage,
							dpi: 1,
						},
					],
					xs: [
						{
							url: currentImage,
							dpi: 1,
						},
					],
				}}
				presentation
			/>
			<ImageByUrl
				className={clsx(styles.slide, hidden && styles.hidden)}
				image={{
					images: [
						{
							url: previousImage,
							dpi: 1,
						},
					],
					xs: [
						{
							url: previousImage,
							dpi: 1,
						},
					],
				}}
				presentation
			/>
		</div>
	);
};
