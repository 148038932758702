import type { MarkdownAstNode } from '@theway/markdown/types';

import type { Offer } from './offer';

export type { Offer };

export type {
	BlockListConfiguration,
	Frequency,
	Publisher,
	PresetTypes,
} from './publisher';
export type { Exit } from './exit';
export type { Merchant } from './merchant';
export type { Giftcard } from './giftcard';
export type { Promotion } from './promotion';

export type AdEnv = 'dev' | 'staging' | 'production';
export type AdCountry = 'AU' | 'US' | 'GB';
export type AdCurrency = 'AUD' | 'USD' | 'GBP';

export type {
	LocationData,
	GeographicModifiers,
	WithGeographicModifiers,
} from './geographic-modifiers';

export type SlotImage = {
	active: string;
	inactive: string;
	bg: string;
	stroke?: string;
};

export type SlotAttributes = {
	experienceId: string;
	trackId?: string | undefined;
	variant?: number;
	/**
	 * @deprecated
	 */
	partnerName: string;
	advertiserId: string;
	name: string;
	title: MarkdownAstNode;
	text: MarkdownAstNode;
	subtext: MarkdownAstNode;
	disclaimer: MarkdownAstNode | undefined;
	screen2Text: MarkdownAstNode | undefined;
	screen2Subtext: MarkdownAstNode | undefined;
	badge?: MarkdownAstNode | undefined;
	promotion?:
		| {
				name: string;
				terms: MarkdownAstNode;
		  }
		| undefined;
	category: string[];
	linkCaption: string;
	termsText: string;
	img: SlotImage;

	communicationAvailable: boolean;

	mg?: {
		image_desktop: string;
		image_mobile: string;
	};

	// TODO replace with new model of intros + intents + offers
	introductions?: Offer['introductions'];
};

export type SplashItem = {
	experienceId: string;
	/**
	 * @deprecated replaced with publisherName
	 */
	partnerName: string;
	publisherName: string;

	mg?: {
		image_desktop: string;
		image_mobile: string;
	};
};

export type SplashSplashItem = {
	type: 'splash';
	text: MarkdownAstNode;
	subtext: MarkdownAstNode;
	positionOfSubtext: 'above' | 'below';
	actionText: string;
	variant?: number;
	mg?: {
		image_desktop: string;
		image_mobile: string;
	};
};

export type SplashOfferItem = {
	type: 'offer';
	offer?: OfferItem;
};

export type SplashSkipItem = {
	type: 'skip';
	skipSplash?: boolean;
};

export type OfferItem = SlotAttributes & {
	href: string;
};

type DualOfferAction = {
	image: string;
	imageBackground: string;
	// stroke: string;
	advertiserId: string;

	actionBackgroundColor: string;
	actionTextColor: string;
};

export type DualOfferItem = SlotAttributes & {
	secondaryLinkCaption: string;
	href: string;
	// secondaryHref: string;

	dualSettings: {
		firstOffer: DualOfferAction;
		secondOffer: DualOfferAction;
	};
};

export const DIRECT_CODE = 'ref:direct';
export type CodeItem = SlotAttributes & {
	code: string;
	href: string;
};

export type ThanksGiveawayItem = SlotAttributes & {
	code: string;
	href: string;
	promotion: NonNullable<SlotAttributes['promotion']>;
};

export type TransactionItem = SlotAttributes & {
	giftcard: {
		reference: string;
		name: string;
		cost: number;
		country: string;
		currency: string;
	};
};

export type SubscriptionItem = SlotAttributes & {
	subscription: {
		/**
		 * @private
		 */
		key: string;

		id: string;
		actionText: string;
		name: string;
		link: string;
		thanksLink: string;
		revenue: number;
		collect: {
			email: boolean;
			name: boolean;
			firstName: boolean;
			lastName: boolean;
			phone: boolean;
			address: boolean;
			suburb: boolean;
			state: boolean;
			postcode: boolean;
			gender: boolean;
		};
	};
};

export type Subscription = SubscriptionItem['subscription'];

export type DonationItem = SlotAttributes & {
	href: string;
};

export type OfferJoke = {
	experienceId: string;
	partnerName: 'thanks';
	question: string;
	answer: string;
	actionText: string;
	replyText: string;

	mg?: {
		image_desktop: string;
		image_mobile: string;
	};
};

export type OfferFact = {
	experienceId: string;
	partnerName: 'thanks';
	text: MarkdownAstNode;
	subText: MarkdownAstNode;
	actionText: string;

	mg?: {
		image_desktop: string;
		image_mobile: string;
	};
};

export type BaseOfferSlot =
	| {
			type: 'offer';
			data: OfferItem;
	  }
	| {
			type: 'dual-offer';
			data: DualOfferItem;
	  }
	| {
			type: 'code';
			data: CodeItem;
	  }
	| {
			type: 'transaction';
			data: TransactionItem;
	  }
	| {
			type: 'subscription';
			data: SubscriptionItem;
	  }
	| { type: 'thanks-giveaway'; data: ThanksGiveawayItem }
	| {
			type: 'joke';
			data: OfferJoke;
	  }
	| {
			type: 'fact';
			data: OfferFact;
	  }
	| {
			type: 'donation';
			data: DonationItem;
	  };

export type WideOfferSlotSplash = SplashItem &
	(SplashOfferItem | SplashSplashItem | SplashSkipItem);

export type OfferSlotSplash = SplashItem & SplashSplashItem;

export type OfferSlot =
	| BaseOfferSlot
	| {
			type: 'splash';
			data: WideOfferSlotSplash;
	  };

export type OfferBranchConfiguration = {
	logo: string;
	background: ReadonlyArray<string> | undefined;
	// type: 'splash' | 'offer' | 'skip';
};

export type OfferBrand = OfferBranchConfiguration &
	SplashItem &
	(SplashOfferItem | SplashSplashItem | SplashSkipItem);

export type ExperimentationStatus = 'off' | 'on' | 'safe';

export type ImpressionSequence = {
	type: 'impressionsequence';
	impressionId: string;
	sessionId?: string;
	piiRegion: string;
	partnerId: string;
	partnerSiteId: string;
	country: AdCountry;
	experimentation: ExperimentationStatus;
	experiment: string;
	deviceMode: ImpressionDevice;
	theme: string;
	statusText: string;
	statusOverride: readonly string[];

	analytics?: {
		hash?: string | undefined;
		traceId?: string | undefined;
		asOrganization?: string | undefined;
		keywords?: string | undefined;
		cost?: number;
		items?: any | undefined;
		experiment?: string | Record<string, string>;
		embedExperiment?: string | undefined;
		embedTheme?: string | undefined;
		overrides: string[];
		flags: Record<string, string | boolean>;
		hasReferral?: boolean | undefined;
		smartWidgetWindowEnd?: string | undefined;
	};

	brand: OfferBrand;
	widget: ImpressionWidget;
	slots: OfferSlot[];
};

export type ImpressionCooldown = Pick<
	ImpressionSequence,
	'brand' | 'impressionId' | 'partnerId' | 'partnerSiteId' | 'sessionId'
> & {
	type: 'impressioncooldown';
	isInPopupCooldown: boolean;
	cooldownEnd: string;
};

export type ImpressionDevice = 'desktop' | 'mobile';

export type EmbedImpression = {
	type: 'embedimpression';
	version?: 1 | 2;
	impressionId: string;
	sessionId?: string;
	partnerId: string;
	partnerSiteId: string;
	country: AdCountry;
	brand: OfferBrand;
	theme: string;
	slot: string | undefined;
	displaySlot: {
		id: string;
		advertiserId: string;
		mode?: 'include' | 'exclude';
		imageHorizontal: string;
		imageVertical: string;
		title: MarkdownAstNode;
		shortTitle: MarkdownAstNode | undefined;
		text: MarkdownAstNode;
		actionText: string;
		firstOffer: {
			bg: string;
			stroke?: string | undefined;
			logo: string;
		};
	};
	analytics: ImpressionSequence['analytics'];
	allowExperimentation?: boolean;
	embedExperiment: ImpressionSequence['experiment'];
	activateDynamicPopup: boolean;
	landingPageSlug: undefined | string;
	setup?: {
		style: string | undefined;
		feature: string | undefined;
	};
};

export type ImpressionError = {
	type: 'impressionerror';
	code?: '404' | '500';
	errorMessage: string;
};

export enum ImpressionWidget {
	// WHITE_IBIS = 'WHITE_IBIS',
	MELEAGRIS_GALLOPAVO = 'MELEAGRIS_GALLOPAVO',
	PLATYCERCUS = 'PLATYCERCUS',
	ZIP = 'ZIP',
}
