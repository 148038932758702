const BASE16_CHARS = '0123456789abcdef';
const BASE62_CHARS =
	'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const BASE64_CHARS =
	'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_.~';

type BASE_OF = 16 | 62 | 64;

const toBase = (number: number, base: string) => {
	if (number < base.length) {
		return base[number];
	}

	let output = '';

	while (number > 0) {
		const remainder = number % base.length;
		number = Math.floor(number / base.length);
		output = base[remainder] + output;

		break;
	}

	return output;
};

const BASES = {
	16: BASE16_CHARS,
	62: BASE62_CHARS,
	64: BASE64_CHARS,
} as const;

export function xorTransform(
	cryptoKey: string,
	input: string,
	radix: BASE_OF = 16,
) {
	const base = BASES[radix];
	const key = cryptoKey.replaceAll('-', '');
	const keyLength = key.length;
	let output = '';

	for (let i = 0; i < input.length; i++) {
		if (input[i] === '-') {
			output += '-';
			continue;
		}

		if (input[i] === '=') {
			output += '=';
			continue;
		}

		const inputCharCode = base.indexOf(input[i]);
		const keyCharCode = base.indexOf(key[i % keyLength]);
		const xorResult = inputCharCode ^ keyCharCode;
		const xorHex = toBase(xorResult, base); //.padStart(2, '0');

		output += xorHex;
	}

	return output;
}

export function rotateString(s: string, shiftHash: number) {
	const len = s.length;

	// Ensure shift is within the string length to avoid unnecessary rotations
	const shift = shiftHash % len;

	if (shift === 0) {
		return s; // No rotation needed
	}

	const rotatedSubstring =
		shift > 0
			? s.substring(shift) + s.substring(0, shift)
			: s.substring(len + shift) + s.substring(0, len + shift);

	// console.log({ shift, cut: len + shift, len, s1: s, s2: rotatedSubstring });

	return rotatedSubstring;
}

function simpleHashNumber(s: string) {
	return (
		s.charCodeAt(0) +
		s.charCodeAt(1) +
		s.charCodeAt(s.length - 1) +
		s.charCodeAt(s.length - 2)
	);
}

const crypter = (key: string, payload: string, hash: number, base: BASE_OF) => {
	const r1 = rotateString(payload, hash);
	const x1 = xorTransform(key, r1, base);
	const r2 = rotateString(x1, -hash);

	// console.log({ payload, r1, x1, r2, hash });

	return r2;
};

export const encrypt = (key: string, payload: string, base: BASE_OF) => {
	const lck = key.toLowerCase();

	return crypter(lck, payload, simpleHashNumber(lck), base);
};

export const decrypt = (key: string, payload: string, base: BASE_OF) => {
	const lck = key.toLowerCase();

	return crypter(lck, payload, simpleHashNumber(lck), base);
};
