import type { SlideType } from '@smile/widget-common/types';
import { analyticsAttribution } from '@theway/analytics';
import { clsx } from 'clsx';
import type { FC, PropsWithChildren } from 'react';

import * as styles from './styles';

export type PaginationControlProps = {
	activeSlide: number;
	maximumSlide: number;
	slides: SlideType[];
	onSetSlide(slide: number): void;
};

type PaginationProps = PaginationControlProps & {
	gap?: number;
};

type PaginationButtonProps = {
	label: string;
	fromSlide: number;
	toSlide: number;
	active: boolean;
	visited: boolean;
	setSlide(slide: number): void;
};

const PaginationButton: FC<PaginationButtonProps & PropsWithChildren> = ({
	label,
	children,
	fromSlide,
	toSlide,
	setSlide,
	active,
	visited,
}) => {
	const isYetDisabled = toSlide > fromSlide;
	const isDisabled = false;

	return (
		<button
			className={clsx(
				styles.action,
				active && styles.active,
				visited && styles.visited,
			)}
			disabled={isDisabled}
			aria-label={label}
			role="tab"
			aria-selected={active}
			title={!isDisabled ? label : undefined}
			tabIndex={active ? 0 : -1}
			onClick={() => setSlide(toSlide)}
			{...analyticsAttribution(
				isYetDisabled ? 'pagination-disabled' : 'pagination',
			)}
		>
			{children}
		</button>
	);
};

export const Pagination: FC<PaginationProps> = ({
	activeSlide,
	maximumSlide,
	slides,
	onSetSlide,
}) => {
	const mod_slides: SlideType[] = [...slides];

	const paginationButtonProps = {
		fromSlide: maximumSlide,
		setSlide: onSetSlide,
	} as const;

	return (
		<ul className={styles.list}>
			{mod_slides.map((slide, index) => {
				const currentSlide = index + 1;

				return (
					<li key={index}>
						<PaginationButton
							active={activeSlide === currentSlide}
							visited={currentSlide <= maximumSlide}
							label={`See offer ${index + 1}`}
							toSlide={currentSlide}
							{...paginationButtonProps}
						>
							{currentSlide === activeSlide ||
							currentSlide <= maximumSlide ? (
								<img
									src={slide.img}
									alt=""
									role="presentation"
									style={{
										borderRadius: '50%',
									}}
								/>
							) : (
								index + 1
							)}
						</PaginationButton>
					</li>
				);
			})}
		</ul>
	);
};
