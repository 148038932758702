import { TextSm } from '@smile/ds/typography';
import type { FC, PropsWithChildren } from 'react';

import * as styles from './styles';

const Triangle = () => (
	<svg
		className={styles.triangle}
		xmlns="http://www.w3.org/2000/svg"
		width={9}
		height={4}
		fill="none"
	>
		<path fill="#1E9191" d="M9 4 0 0h9v4Z" />
	</svg>
);

export const Badge: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className={styles.badge}>
			<TextSm as="span" weight="bold">
				{children}
			</TextSm>
			<Triangle />
		</div>
	);
};
