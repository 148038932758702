export const preloadImage = (src: string | undefined): Promise<void> => {
	return new Promise((resolve) => {
		if (!src) {
			resolve();

			return;
		}

		const img = new Image();

		img.onload = () => {
			resolve();
		};

		img.onerror = () => {
			resolve();
		};

		img.src = src;
	});
};

export const preloadMediaImage = (set: {
	image_desktop?: string;
	image_mobile?: string;
}): Promise<void> => {
	return preloadImage(
		// should be in sync with workspace/platform/meleagris-gallopavo/src/image-by-url/ImageByUrl.tsx
		window.innerWidth > 767 ? set.image_desktop : set.image_mobile,
	);
};
