import { InlineLink } from '@smile/ds/actions';
import type { AstComponents } from '@theway/markdown/from-ast';
import { fromAst } from '@theway/markdown/from-ast';
import type { MarkdownAstNode } from '@theway/markdown/types';

const components: AstComponents = {
	strong: ({ children }) => <strong>{children}</strong>,
	delete: ({ children }) => <s>{children}</s>,
	emphasis: ({ children }) => <em>{children}</em>,
	link: ({ children, url }) => <InlineLink href={url}>{children}</InlineLink>,
};

export const fromMarkAst = (ast: MarkdownAstNode | undefined) =>
	ast ? fromAst(ast, components) : ast;
