import type { FC } from 'react';

export type ImageLoadingBehaviour = 'eager' | 'auto';

type ImageRecord = {
	url: string;
	width?: number;
	dpi?: number;
};

type ImageType = {
	width?: number;
	height?: number;
	images: ImageRecord[];
	images_webp?: ImageRecord[];
	xs?: ImageRecord[];
	sm?: ImageRecord[];
};

type ImageByUrlType = {
	image: ImageType;

	loading?: ImageLoadingBehaviour;
} & (
	| {
			alt: string;
			presentation?: never;
	  }
	| { presentation: boolean; alt?: never }
);

type ComponentType = ImageByUrlType & { className?: string };

export const toDPISrcSet = (images: ImageRecord[]) =>
	images.map((img) => `${img.url} ${img.dpi}x`).join(', ');

export const ImageByUrl: FC<ComponentType> = ({
	className,
	image,
	alt,
	presentation,
	loading,
}) => (
	<picture className={className}>
		{image.xs && (
			<source srcSet={toDPISrcSet(image.xs)} media="(max-width: 767px)" />
		)}
		{image.images_webp && (
			<source type="image/webp" srcSet={toDPISrcSet(image.images_webp)} />
		)}
		<img
			src={image.images[0].url}
			srcSet={image.images.length ? toDPISrcSet(image.images) : undefined}
			width={image.width || '100%'}
			height={image.height || '100%'}
			loading={loading === 'eager' ? 'eager' : 'lazy'}
			alt={alt}
			role={presentation ? 'presentation' : undefined}
		/>
	</picture>
);
