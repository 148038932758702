import { useCustomerCommunication } from '@smile/context';
import { useFeatureFlag } from '@smile/experimentation';
import { decodePersonalToken } from '@smile/personal-token';
import { concatSearchPart } from '@smile/url-utils';
import type { TransactionItem } from '@thanks/impression-type';
import type { FC, ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { currentLinkTrackers } from '../analytics/tracker-link';
import { getPiiToken } from '../services/pii/pii-bridge';

import { environmentDi } from './environmentDi';

declare module '@smile/experimentation' {
	export interface FeatureFlags {
		/**
		 * enables stripe checkout
		 */
		stripeCheckout: boolean;
	}
}

export type PaymentInformation = {
	apiKey: string;
	intentSecret: string;
	redirectUrl?: string | undefined;
	cost: number;
};
type CodeGetter = () => Promise<PaymentInformation | undefined>;
type CodeStatus = 'ready' | 'loading' | 'error';

type Params = {
	email: string | undefined;
};

type CodeCallbacks = {
	getCode: CodeGetter;
	usesExternalWindow?: boolean;
	onBeforeSettle?(override: { email: string }): Promise<boolean>;
	onAfterSettle?(): Promise<void>;
};

const DynamicIntentGiftcardFetcher: FC<{
	offer: TransactionItem;
	impression: string;
	tokenExtra: string;
	email: string | undefined;
	onNext(): void;
	render(
		callbacks: CodeCallbacks,
		params: Params,
		status: CodeStatus,
	): ReactElement;
}> = ({ render, offer, email, impression, tokenExtra, onNext }) => {
	const [status, setStatus] = useState<CodeStatus>('loading');
	const [paymentInfo, setPaymentInfo] = useState<
		PaymentInformation | undefined
	>();

	const getCode = useCallback(
		async () => {
			const url = new URLSearchParams({
				impression,
				offer: offer.experienceId,
				...currentLinkTrackers(),
			});

			try {
				const code = await fetch(
					concatSearchPart(
						'/api/payment/intent?' + url.toString(),
						tokenExtra,
					),
				);

				if (!code.ok || code.status !== 200) {
					setStatus('error');
				}

				const data = (await code.json()) as any;

				const paymentInfo: PaymentInformation = {
					apiKey: data.stripeApiKey,
					intentSecret: data.clientSecret,
					cost: data.cost,
				};

				setPaymentInfo(paymentInfo);

				setStatus('ready');

				return paymentInfo;
			} catch (e) {
				console.error(e);
				setStatus('error');

				return undefined;
			}
		}, // TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[offer, tokenExtra],
	);

	const onBeforeSettle = useCallback(
		async (overrides: { email: string }) => {
			try {
				const url = new URLSearchParams({
					...overrides,
					intent: paymentInfo?.intentSecret || '',
					impression,
					offer: offer.experienceId,
				});
				const code = await fetch(
					'/api/payment/try-lock-transaction?' + url,
				);

				if (!code.ok || code.status !== 200) {
					console.error('cannot allocate giftcard');

					return false;
				}

				return true;
			} catch (e) {
				console.error(e);

				return false;
			}
		},
		// TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[paymentInfo],
	);

	const onAfterSettle = useCallback(() => {
		setTimeout(() => onNext(), 2500);

		return Promise.resolve(undefined);
	}, [onNext]);

	return render(
		{
			getCode,
			onAfterSettle,
			onBeforeSettle,
		},
		{ email },
		paymentInfo ? 'ready' : status,
	);
};

const DynamicCheckoutGiftcardFetcher: FC<{
	offer: TransactionItem;
	impression: string;
	tokenExtra: string;
	email: string | undefined;
	onNext(): void;
	render(
		callbacks: CodeCallbacks,
		params: Params,
		status: CodeStatus,
	): ReactElement;
}> = ({ render, offer, email, impression, tokenExtra }) => {
	const [status, setStatus] = useState<CodeStatus>('loading');
	const [paymentInfo, setPaymentInfo] = useState<
		PaymentInformation | undefined
	>();

	const getCode = useCallback(
		async () => {
			const url = new URLSearchParams({
				impression,
				offer: offer.experienceId,
				email: email || '',
				...currentLinkTrackers(),
			});

			try {
				const code = await fetch(
					concatSearchPart(
						'/api/payment/checkout?' + url.toString(),
						tokenExtra,
					),
				);

				if (!code.ok || code.status !== 200) {
					setStatus('error');
				}

				const data = (await code.json()) as any;

				const paymentInfo: PaymentInformation = {
					apiKey: data.stripeApiKey,
					intentSecret: data.clientSecret,
					redirectUrl: data.redirectUrl,
					cost: data.cost,
				};

				setPaymentInfo(paymentInfo);

				setStatus('ready');

				return paymentInfo;
			} catch (e) {
				console.error(e);
				setStatus('error');

				return undefined;
			}
		}, // TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[offer, tokenExtra, email],
	);

	return render(
		{
			getCode,
			usesExternalWindow: true,
		},
		{ email },
		paymentInfo ? 'ready' : status,
	);
};

const RealGiftcardFetcher: FC<{
	offer: TransactionItem;
	impression: string;
	piiRegion: string;
	onNext(): void;
	children(
		callbacks: CodeCallbacks,
		params: Params,
		status: CodeStatus,
	): ReactElement;
}> = ({ children, offer, impression, piiRegion, onNext }) => {
	const [token, setToken] = useState<string | undefined>(undefined);
	const [prefilledEmail, setPrefilledEmail] = useState<string | undefined>(
		undefined,
	);

	const { customerSharedEmail } = useCustomerCommunication();

	useEffect(
		() => {
			const getToken = getPiiToken({
				impression,
				piiRegion,
				subject: 'autofill',
				offerType: 'transaction',
				offer,
				customerSharedEmail,
			});

			getToken.then((token) => {
				if (!token) {
					return;
				}

				setToken(token);

				const { email } = decodePersonalToken(impression, token);
				setPrefilledEmail(email);
			});
		}, // TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const tokenExtra = token ? `piitoken=${encodeURIComponent(token)}` : '';

	const FetcherComponent = useFeatureFlag('stripeCheckout')
		? DynamicCheckoutGiftcardFetcher
		: DynamicIntentGiftcardFetcher;

	return (
		<FetcherComponent
			render={children}
			offer={offer}
			impression={impression}
			tokenExtra={tokenExtra}
			email={prefilledEmail}
			onNext={onNext}
		/>
	);
};

const FakeGiftcardFetcher: typeof RealGiftcardFetcher = ({ children }) => {
	return children(
		{
			getCode: () => Promise.resolve(undefined),
			onAfterSettle: () => Promise.resolve(undefined),
			onBeforeSettle: () => Promise.resolve(true),
		},
		{ email: undefined },
		'error',
	);
};

export const GiftcardFetcher = environmentDi(RealGiftcardFetcher, {
	test: FakeGiftcardFetcher,
	preview: FakeGiftcardFetcher,
});
