import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

export type AnalyticsConnector = {
	onEvent: (event: string, origin: Element | EventTarget | null) => void;
};

const analyticsContext = createContext<AnalyticsConnector>({
	onEvent() {
		//
	},
});

export const AnalyticsContext: FC<
	PropsWithChildren<{
		connector: AnalyticsConnector;
	}>
> = ({ children, connector }) => (
	<analyticsContext.Provider value={connector}>
		{children}
	</analyticsContext.Provider>
);

export const useAnalyticsEvent = () => useContext(analyticsContext).onEvent;
