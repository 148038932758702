import { useContext } from 'react';
import { type ReactNode, type FC, createContext } from 'react';

import { ColorScheme } from './types';

const ColorSchemeContext = createContext<ColorScheme>(ColorScheme.light);

/**
 * Provides new color scheme to the children
 */
export const ColorSchemeProvider: FC<{
	scheme: ColorScheme;
	children: ReactNode;
}> = ({ children, scheme }) => (
	<ColorSchemeContext.Provider value={scheme}>
		{children}
	</ColorSchemeContext.Provider>
);

/**
 * Matches the current theme mode
 * @example
 * ```ts
 *  const styles = useThemeMode({
 *    light: styles.light,
 *    dark: styles.dark
 *  });
 * ```
 */
export const useColorScheme = <T,>(
	matches: Record<ColorScheme, T>,
	schemeOverride?: ColorScheme,
): T => {
	const currentScheme = useContext(ColorSchemeContext);

	return matches[schemeOverride || currentScheme];
};
