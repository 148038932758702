import type { SubscriptionItem } from '@thanks/impression-type';
import type { FC, ReactElement } from 'react';

import { currentLinkTrackers } from '../analytics/tracker-link';
import { usePrefilled, type PrefilledInfo } from '../utils/hooks';

type ProcessData = (
	data: Record<string, string>,
	options: { agreedToThanksSubscription: boolean },
) => Promise<boolean>;

export const SubscriptionController: FC<{
	offer: SubscriptionItem;
	impression: string;
	piiRegion: string;
	onNext(): void;
	children(props: {
		prefilled: PrefilledInfo | undefined;
		processData: ProcessData;
	}): ReactElement;
}> = ({ children, offer, impression, piiRegion, onNext }) => {
	const { prefilled } = usePrefilled({
		impressionId: impression,
		piiRegion,
		offer,
	});

	const processData: ProcessData = async (data, options) => {
		try {
			const url = new URLSearchParams({
				impression,
				offer: offer.experienceId,
				...currentLinkTrackers(),
			});
			const code = await fetch('/api/subscribe?' + url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					...data,
					options,
					// key: offer.subscription.
				}),
			});

			// report only "known" errors
			// - 409 - user is already in
			// - 500 - internal server error, retry
			// - all other - "happy", move forward
			// > why: decision paralysis.
			if (code.status === 409 || code.status >= 500) {
				throw new Error('Failed to subscribe');
			}

			setTimeout(() => onNext(), 1500);

			return true;
		} catch (e) {
			console.error(e);
			throw e;

			// return false;
		}
	};

	return children({ prefilled, processData });
};
