import { kashe } from 'kashe';
import { useCallback, useEffect, useRef } from 'react';

const defaultInstance = {};

// todo: scope to react instance
const getCache = kashe((_instance: any) => new Set<any>());

export const useGetGlobalAttributes = () => {
	return useCallback((_type: string) => {
		const cache = getCache(defaultInstance);

		return Array.from(cache.values()).reduce((acc, item) => {
			Object.assign(acc, item.current);

			return acc;
		}, {} as any);
	}, []);
};

export const setGlobalAnalyticsAttributes = (
	_types: string[],
	attributes: Record<string, any>,
) => {
	const ref = { current: attributes };
	const cache = getCache(defaultInstance);
	cache.add(ref);

	return () => {
		cache.delete(ref);
	};
};

export const useGlobalAnalyticsAttributes = (
	_types: string[],
	attributes: Record<string, any>,
) => {
	const ref = useRef<any>(null);
	ref.current = attributes;

	useEffect(() => {
		const cache = getCache(defaultInstance);
		cache.add(ref);

		return () => {
			cache.delete(ref);
		};
	}, []);
};
