import { css } from '@linaria/core';

export const animatedAppearanceFromBottom = css`
	.thanks-ready & {
		animation: appearingFromBottom 0.3s ease-in-out forwards;
	}
	animation-delay: 100ms !important;
	opacity: 0;

	@keyframes appearingFromBottom {
		0% {
			opacity: 0;
			transform: translateY(25%);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;
