import type { ScatteredContinuousState } from '@theuiteam/continuous-container';
import { useScatteredContinuousState } from '@theuiteam/continuous-container';
import { PopupTeleporterSource } from '@theway/shards';
import { clsx } from 'clsx';
import { forwardRef, useCallback, useState } from 'react';
import type { ReactNode } from 'react';
import { useMergeRefs } from 'use-callback-ref';

import { useAutoFocusRef } from '../a11y';
import { IconButton } from '../actions';
import { CloseIcon } from '../icons/Close';
import type { SurfaceColorType } from '../surface';
import { Surface } from '../surface';

import * as styles from './styles';
import { suppressEvent } from './utils';

export type ModalProps = {
	children: ReactNode;
	closeCaption?: ReactNode;
	visible: boolean;
	color?: SurfaceColorType;
	onClose(): void;
};

export type ModalControl = {
	modal: ScatteredContinuousState<boolean>;
	open(): void;
	close(): void;
	toggle(): void;
};

export const useControlModal = (): ModalControl => {
	const [modalVisible, setModalVisible] = useState(false);
	const modal = useScatteredContinuousState(modalVisible, {
		delayPast: 150,
	});
	const close = useCallback(() => setModalVisible(false), []);
	const open = useCallback(() => setModalVisible(true), []);
	const toggle = useCallback(
		() => setModalVisible((visible) => !visible),
		[],
	);

	return {
		modal,
		close,
		open,
		toggle,
	};
};

export const ModalPortal = forwardRef<HTMLDivElement, ModalProps>(
	function ModalTarget(
		{
			visible,
			onClose,
			children,
			closeCaption = 'Close',
			color = 'tertiary',
		},
		ref,
	) {
		const innerRef = useAutoFocusRef<HTMLDivElement>();
		const modalRef = useMergeRefs([innerRef, ref]);

		return (
			<Surface
				as="div"
				className={clsx(styles.component, visible && styles.visible)}
				tabIndex={-1}
				role="dialog"
				aria-modal={true}
				ref={modalRef}
				color={color}
			>
				<div className={styles.container} onClick={suppressEvent}>
					{children}
				</div>
				<div className={styles.close}>
					<IconButton
						onClick={onClose}
						icon={<CloseIcon />}
						actionName="modal-close"
					>
						{closeCaption}
					</IconButton>
				</div>
			</Surface>
		);
	},
);

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
	function ModalSource(props, ref) {
		return (
			<PopupTeleporterSource>
				<ModalPortal {...props} ref={ref} />
			</PopupTeleporterSource>
		);
	},
);
