import { xorTransform } from '@theway/helpers-fake-crypto';

type SubscriberProperties = {
	email?: string | undefined;
	firstName?: string | undefined;
	lastName?: string | undefined;
	phone?: string | undefined;
	state?: string | undefined;
	postcode?: string | undefined;
	gender?: 'Male' | 'Female' | 'Prefer not to say' | undefined;
};

export type PersonalToken = SubscriberProperties & {
	piId: string;
};

export const encodePersonalToken = (hash: string, token: PersonalToken) => {
	const base64 = btoa(JSON.stringify(token));

	return xorTransform(hash, base64, 64);
};

export const decodePersonalToken = (
	hash: string,
	token: string,
): PersonalToken => {
	const data = xorTransform(hash, token, 64);

	return JSON.parse(atob(data));
};
