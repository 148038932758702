import { css } from '@linaria/core';
import { from, Media } from '@theway/helpers-css/breakpoints';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const header = css`
	display: flex;
	flex-direction: column;
	row-gap: 8px;

	padding-inline: 24px;
	padding-top: 14px;
	padding-bottom: 16px;

	${container('content', 'width < 358px')} {
		padding-inline: 20px;
	}

	${from(Media.SM)} {
		row-gap: 16px;

		padding-inline: 40px;
		padding-bottom: 0;
	}
`;

export const title = css`
	display: flex;
	align-items: center;
	column-gap: 4px;

	& strong {
		font-weight: 600;
	}
`;
