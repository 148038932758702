import type { FC, ReactNode } from 'react';

/**
 * used to break the render order and execute children inside Suspense boundary
 */
export const SuspendedRender: FC<{ children(): ReactNode }> = ({
	children,
}) => {
	return <>{children()}</>;
};
