import type { OfferSlot } from '@thanks/impression-type';
import { useMemo } from 'react';

export const useAdProps = (slot: OfferSlot) => {
	return useMemo(() => {
		switch (slot?.type) {
			case 'dual-offer': {
				const { firstOffer, secondOffer } = slot.data.dualSettings;

				return {
					offerType: 'dual',
					firstBrand: {
						img: firstOffer.image,
						color: firstOffer.imageBackground,
					},
					secondBrand: {
						img: secondOffer.image,
						color: secondOffer.imageBackground,
					},
				} as const;
			}
		}

		return {} as const;
	}, [slot]);
};
