import { ImpressionWidget } from '@thanks/impression-type';
import type { ComponentProps, FC } from 'react';

import * as meleagrisContol from '../presets/meleagris-gallopavo/load-control';
import { MeleagrisGallopavoApp } from '../presets/meleagris-gallopavo/meleagris-gallopavo-app';

import * as platycercusContol from '../presets/platycercus/load-control';
import { PlatycercusApp } from '../presets/platycercus/platycercus-app';

import * as zipContol from '../presets/zip/load-control';
import { ZipApp } from '../presets/zip/zip-app';

const mapping = {
	[ImpressionWidget.MELEAGRIS_GALLOPAVO]: {
		app: MeleagrisGallopavoApp,
		...meleagrisContol,
	},
	[ImpressionWidget.PLATYCERCUS]: {
		app: PlatycercusApp,
		...platycercusContol,
	},
	[ImpressionWidget.ZIP]: {
		app: ZipApp,
		...zipContol,
	},
} as const;

const DEFAULT_WIDGET = ImpressionWidget.MELEAGRIS_GALLOPAVO;

export const getAppRenderer = (widget: ImpressionWidget | undefined) =>
	mapping[widget ?? DEFAULT_WIDGET];

export const ViewSelector: FC<
	ComponentProps<
		(typeof mapping)[ImpressionWidget.MELEAGRIS_GALLOPAVO]['app']
	> & {
		app?: ImpressionWidget | undefined;
	}
> = (props) => {
	const App = getAppRenderer(props.app ?? props.impression.widget).app;

	return <App {...props} />;
};
