import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';
import { customVariable } from '@theway/helpers-css/variables';

export const logoVar = customVariable('logo-size', (x) => `${x}px`);
export const bgVar = customVariable('partner-bg-color', (x) => `${x}`);
export const ratioVar = customVariable('ratio', (x) => `${x}`);

export const donateBgVar = customVariable('donate-bg', (x) => `${x}`);
export const donateStrokeVar = customVariable('donate-stroke', (x) => `${x}`);

export const desktop = css`
	min-width: 422px;
	height: 100%;
	background-color: ${palette.powerHouse[500]};
`;

export const mobile = css`
	position: absolute;
	inset: 0;
	top: 46px;

	width: 100%;
	height: 100%;

	border-radius: 16px 16px 0 0;
	background-color: ${palette.powerHouse[500]};

	transform: translateY(100%);

	transition-duration: 200ms;
	transition-timing-function: ease-in-out;
	transition-property: transform;

	overflow: hidden;
`;

export const visible = css`
	transform: translateY(0);
`;

export const image = css`
	*:where(.${mobile}) & {
		display: block;
		width: 100%;
		height: 120px;
	}

	*:where(.${desktop}) & {
		display: block;
		width: 100%;
		height: 100%;
	}

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top center;
	}
`;

export const single = css`
	*:where(.${mobile}) & {
		display: flex;
		flex-direction: column;

		padding-inline: 24px;
		padding-top: 30px;
	}

	*:where(.${desktop}) & {
		display: flex;
		flex-direction: column;

		padding-inline: 40px;
		padding-top: 40px;
	}
`;

export const exit = css`
	*:where(.${mobile}) & {
		display: flex;
		flex-direction: column;

		padding-inline: 24px;
		padding-top: 30px;
	}

	*:where(.${desktop}) & {
		display: flex;
		flex-direction: column;

		padding-inline: 40px;
		padding-top: 40px;
	}
`;

export const multi = css`
	*:where(.${mobile}) & {
		display: flex;
		flex-direction: column;
		row-gap: 36px;

		padding-inline: 24px;
		padding-top: 30px;
	}

	*:where(.${desktop}) & {
		display: flex;
		flex-direction: column;
		row-gap: 16px;

		padding-inline: 40px;
		padding-top: 40px;
	}
`;

export const partnershipComponent = css`
	position: relative;

	display: inline-flex;
`;

export const partnershipLogo = css`
	position: relative;
	width: ${String(logoVar)};
	height: ${String(logoVar)};

	border-radius: 9999px;

	background-color: #002140;
`;

export const partnershipBrand = css`
	display: block;
	height: ${String(logoVar)};
	aspect-ratio: ${String(ratioVar)};

	border-radius: 9999px;
	border: 2px solid transparent;

	background-color: ${String(bgVar)};

	& img {
		width: 100%;
		height: 100%;

		object-fit: contain;
		object-position: center;
	}
`;

export const partnershipSvg = css`
	height: ${String(logoVar)};
	margin-left: calc(${String(logoVar)} * -1);
	margin-right: calc(${String(logoVar)} * -1);
`;

export const subtext = css`
	*:where(.${single}) & {
		margin-top: 24px;
	}

	*:where(.${exit}) & {
		margin-top: 24px;
	}
`;

export const text = css`
	*:where(.${single}) & {
		margin-top: 8px;
	}

	*:where(.${exit}) & {
		margin-top: 8px;
	}
`;

export const buttonsGroup = css`
	*:where(.${single}) & {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		row-gap: 8px;

		margin-top: 36px;
	}

	*:where(.${multi}) & {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	*:where(.${exit}) & {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		margin-top: 36px;
	}
`;

export const list = css`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 12px;
`;

export const item = css``;

export const donate = css`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 16px;

	width: 100%;
	height: 56px;
	padding-block: 8px;
	padding-inline: 12px;

	background-color: ${palette.base.white};
	border-radius: 30px;
	border: 2px solid ${palette.base.white};

	overflow: hidden;

	&::before {
		position: absolute;
		inset-block: 0;
		left: 0;
		right: 100%;
		content: '';

		display: block;
		width: auto;

		background-color: #ffd4f1;
		border-radius: 6px;

		transition-timing-function: linear;
		transition-duration: 500ms;
		transition-property: right;
	}
`;

export const donateImage = css`
	position: relative;

	& img {
		width: 40px;
		height: 40px;

		border-radius: 50%;
		border: 2px solid ${String(donateStrokeVar)};

		background-color: ${String(donateBgVar)};
	}
`;

export const active = css`
	border-color: ${palette.baseBlue[500]};

	& .${donateImage}::before {
		position: absolute;
		inset: 0;
		content: '';

		display: block;
		width: 40px;
		height: 40px;

		border-radius: 50%;
		background-color: #ffd4f1;
		background-image: url('./assets/check.svg');
	}

	&::before {
		right: 0;
	}
`;

export const org = css`
	position: relative;
	display: flex;
	flex-direction: column;
`;

export const empty = css`
	display: block;
	width: 100%;
	height: 100%;

	background-color: #cccccc;
`;
