import type { FC } from 'react';

import * as styles from './styles';

type BrandType = {
	color: string;
	img: string;
};

export const DualPartners: FC<{
	firstBrand: BrandType;
	secondBrand: BrandType;
	height: number;
}> = ({ firstBrand, secondBrand, height }) => {
	const icons = `<svg viewBox="0 0 104 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M82.2447 0C75.6509 0 69.7468 2.93585 65.7572 7.56546C65.2043 8.11222 64.6513 8.66492 64.0984 9.21762C58.8364 14.4891 50.6848 15.9451 43.9543 12.7537C42.7057 12.1594 41.5403 11.3868 40.3393 10.6855C40.4582 10.8222 40.5831 10.9589 40.7079 11.0897C42.0338 12.409 43.3657 13.7284 44.6916 15.0477C52.0761 22.4052 59.4488 29.7686 66.8453 37.1082L66.8691 37.132C67.0177 37.2805 67.1604 37.4232 67.309 37.5718C67.8798 38.1363 68.516 38.6474 69.1701 39.1288C69.2057 39.1585 69.2414 39.1823 69.283 39.212C69.4079 39.3012 69.5327 39.3903 69.6576 39.4795C73.2131 42.0052 77.5535 43.491 82.2447 43.491C94.261 43.491 104 33.7563 104 21.7455C104 9.73466 94.261 0 82.2447 0Z" fill="var(--second-brand-color)"/>
<path d="M21.3246 43.491C27.7878 43.491 33.575 40.5552 37.4855 35.9255C38.0275 35.3788 38.5695 34.8261 39.1115 34.2734C44.2693 29.0019 52.2595 27.5459 58.8567 30.7373C60.0806 31.3316 61.2229 32.1042 62.4001 32.8055C62.2836 32.6688 62.1612 32.5321 62.0388 32.4013C60.7392 31.082 59.4337 29.7626 58.1341 28.4433C50.8957 21.0858 43.669 13.7224 36.419 6.3828L36.3957 6.35903C36.25 6.21045 36.1101 6.06782 35.9644 5.91925C35.405 5.35466 34.7814 4.84356 34.1403 4.36218C34.1053 4.33246 34.0703 4.30869 34.0295 4.27897C33.9072 4.18983 33.7848 4.10068 33.6624 4.01154C30.1773 1.4917 25.9228 0 21.3246 0C9.54622 0 0 9.73466 0 21.7455C0 33.7563 9.54622 43.491 21.3246 43.491Z" fill="var(--first-brand-color)"/>
</svg>
`;

	return (
		<div
			className={styles.dual}
			style={{
				...styles.heightVar(height),
				...styles.firstBrandVar(firstBrand.color),
				...styles.secondBrandVar(secondBrand.color),
			}}
		>
			<div dangerouslySetInnerHTML={{ __html: icons }} />
			<div className={styles.firstBrand}>
				<img
					src={firstBrand.img}
					alt=""
					role="presentation"
					loading="eager"
				/>
			</div>
			<div className={styles.secondBrand}>
				<img
					src={secondBrand.img}
					alt=""
					role="presentation"
					loading="eager"
				/>
			</div>
		</div>
	);
};
