import { getConsoleAnalytics } from './console-stub';

import { addAnalyticsAdapter, addLazyAnalyticsAdapter } from './index';

export const lazyInitWidgetAnalytics = () => {
	if (import.meta.env.DEV) {
		addAnalyticsAdapter(getConsoleAnalytics());
	}

	// disable, but not remove completely
	// addLazyAnalyticsAdapter(() =>
	// 	import('./bridge-amplitude').then((m) => m.default),
	// );

	addLazyAnalyticsAdapter(() =>
		import('./bridge-user-flux').then((m) => m.default),
	);
};
