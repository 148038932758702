import type { OfferEffectActions, SlideType } from '@smile/widget-common/types';
import type {
	ImpressionSequence,
	OfferSlot,
	OfferSlotSplash,
} from '@thanks/impression-type';

import type { SlotBroker } from '@theway/type-broker';

import { getCommonProps } from './analytics';

const getNavigationProps = (
	renderedStep: number,
	maximumSlide: number,
	stepLimit: number,
	slots: OfferSlot[],
	onSetSlide: (stepNumber: number) => void,
	stepImages: SlideType[],
) => ({
	slides: stepImages,
	// FIXME: renderedStep is based on `slots` (splash + slides), this causes a "index shift"
	activeSlide: renderedStep,
	maximumSlide,
	// FIXME: renderedStep is based on `slots` (splash + slides), this causes a "index shift"
	isLastOffer: renderedStep >= stepLimit - 1,
	onSetSlide,
	withoutBrand: !slots.some((slot) => slot.type === 'fact'),
});

export const renderStepX1 = (
	renderedStep: number,
	{
		maximumViewedStep,
		stepLimit,
		slots,
		stepImages,
		onSetSlide,
		stepEnterTime,
		impression,
		advance,
		onClose,
		slotRender,
	}: {
		maximumViewedStep: number;
		stepLimit: number;

		slots: OfferSlot[];
		stepImages: SlideType[];
		stepEnterTime: number;

		impression: ImpressionSequence;

		onSetSlide: (stepNumber: number) => void;
		advance: () => void;
		onClose: () => void;
		slotRender: SlotBroker<string, unknown>;
	},
	{
		Splash,
	}: {
		Splash: (
			slide: OfferSlotSplash,
			events: OfferEffectActions,
			props: ReturnType<typeof getNavigationProps>,
		) => JSX.Element;
	},
) => {
	const maximumSlide = Math.max(renderedStep, maximumViewedStep);
	const navigationProps = getNavigationProps(
		renderedStep,
		maximumSlide,
		stepLimit,
		slots,
		onSetSlide,
		stepImages,
	);

	const slide = slots[renderedStep];

	const events = getCommonProps(
		{
			slot: slide,
			position: renderedStep,
			stepEnterTime: stepEnterTime,
			jumpWidth:
				// @ts-expect-error fix later
				renderedStep === 0 && slide.data.type === 'offer' ? 2 : 1,
			impression,
		},
		{ advance },
	);

	if (renderedStep === 0) {
		if (slide.type !== 'splash') {
			return <>impossiblestate </>;
		}

		if (slide.data.type === 'splash') {
			return Splash(slide.data, events, navigationProps);
		}

		if (slide.data.type === 'offer') {
			return 'unsupported type';
		}

		return null;
	}

	if (slide) {
		if (slide.type === 'splash') {
			return <>impossible state </>;
		}

		const commonProps = {
			...navigationProps,
			...events,
		};

		return slotRender.render(slide, commonProps, impression, {
			onClose,
		});
	}

	return null;
};
