import { useFeatureFlag } from '@smile/experimentation';
import { DIRECT_CODE } from '@thanks/impression-type';
import { fromMarkAst } from '@theway/markdown-ui';
import {
	composeFactory,
	createSlotRender,
	renderer,
} from '@theway/type-broker';

import { InlineLink } from '@zip/widget';

import { useLinkTrackers, WithLinkTracker } from '../../analytics/tracker-link';
import { CouponFetcher } from '../../components/coupon-fetcher';
import { GiftcardFetcher } from '../../components/giftcard-fetcher';
import { SubscriptionController } from '../../components/subscription-controller';
import { ThanksGiveawayController } from '../../components/thanks-giveaway-controller';

const offerRenderer = renderer(
	'offer',
	() => import('@zip/widget/offer'),
	(_type, { OfferLink }, data, props) => (
		<WithLinkTracker url={data.href}>
			{(href) => (
				<OfferLink
					{...props}
					{...data}
					href={href}
					text={fromMarkAst(data.text)}
					subtext={fromMarkAst(data.subtext)}
					disclaimer={fromMarkAst(data.disclaimer)}
					linkCaption={data.linkCaption}
				/>
			)}
		</WithLinkTracker>
	),
);

const doubleOfferRenderer = renderer(
	'dual-offer',
	() => import('@zip/widget/offer'),
	(_type, { DualOffer }, data, props) => (
		<DualOffer
			{...props}
			{...data}
			text={fromMarkAst(data.text)}
			subtext={fromMarkAst(data.subtext)}
			disclaimer={fromMarkAst(data.disclaimer)}
			firstOffer={{
				caption: data.linkCaption,
				href: useLinkTrackers(data.href, { order: 1 }),
				advertiserId: data.dualSettings.firstOffer.advertiserId,
				bgColor: data.dualSettings.firstOffer.actionBackgroundColor,
				textColor: data.dualSettings.firstOffer.actionTextColor,
			}}
			secondOffer={{
				caption: data.secondaryLinkCaption,
				href: useLinkTrackers(data.href, { order: 2 }),
				advertiserId: data.dualSettings.secondOffer.advertiserId,
				bgColor: data.dualSettings.secondOffer.actionBackgroundColor,
				textColor: data.dualSettings.secondOffer.actionTextColor,
			}}
		/>
	),
);

const codeRenderer = renderer(
	'code',
	() => import('@zip/widget/offer'),
	(_type, { CouponOffer, RevealCodeOffer }, data, props, impression) => (
		<CouponFetcher
			offer={data}
			impression={impression.impressionId}
			piiRegion={impression.piiRegion}
		>
			{(code, getCode, status, href) =>
				code !== DIRECT_CODE ? (
					<RevealCodeOffer
						{...props}
						{...data}
						href={href}
						code={code}
						getCode={getCode}
						status={status}
						text={fromMarkAst(data.text)}
						subtext={fromMarkAst(data.subtext)}
						disclaimer={fromMarkAst(data.disclaimer)}
						linkCaption={data.linkCaption}
					/>
				) : (
					<CouponOffer
						{...props}
						{...data}
						href={href}
						text={fromMarkAst(data.text)}
						subtext={fromMarkAst(data.subtext)}
						disclaimer={fromMarkAst(data.disclaimer)}
						linkCaption={data.linkCaption}
					/>
				)
			}
		</CouponFetcher>
	),
);

const giftcardRenderer = renderer(
	'transaction',
	() => import('@zip/widget/offer'),
	(_type, { GiftCardOffer }, data, props, impression) => (
		<GiftcardFetcher
			offer={data}
			impression={impression.impressionId}
			piiRegion={impression.piiRegion}
			onNext={props.onNext}
		>
			{({ getCode, usesExternalWindow }, { email }) => (
				<GiftCardOffer
					{...props}
					{...data}
					email={email}
					product={{
						country: data.giftcard.country,
						currency: data.giftcard.currency,
						price: data.giftcard.cost,
						description: data.giftcard.name,
						// activeSlide starts from 1, not 0
						brand: props.slides[props.activeSlide - 1],
					}}
					promo={data.promotion?.name}
					text={fromMarkAst(data.text)}
					subtext={fromMarkAst(data.subtext)}
					disclaimer={fromMarkAst(data.disclaimer)}
					usesExternalWindow={usesExternalWindow || false}
					getPaymentInformation={getCode}
					linkCaption={data.linkCaption}
				/>
			)}
		</GiftcardFetcher>
	),
);

const SKIMM_US = '01564487-9e2b-48e9-9612-e5a1df8d64a1';

const subscriptionRenderer = renderer(
	'subscription',
	() => import('@zip/widget/offer'),
	(_type, { SubscriptionOffer }, data, props, impression) => {
		const slotImage = (data.mg || impression.brand.mg)?.image_mobile || '';

		const singleFieldSubExperiment =
			[SKIMM_US].includes(data.advertiserId) &&
			!!useFeatureFlag('single-field-sub');

		const actionName = singleFieldSubExperiment ? 'Enter now' : 'Subscribe';

		return (
			<SubscriptionController
				offer={data}
				impression={impression.impressionId}
				piiRegion={impression.piiRegion}
				onNext={props.onNext}
			>
				{({ prefilled, processData }) => (
					<SubscriptionOffer
						{...props}
						{...data}
						text={fromMarkAst(data.text)}
						subtext={fromMarkAst(data.subtext)}
						screen2Text={fromMarkAst(data.screen2Text)}
						screen2Subtext={fromMarkAst(data.screen2Subtext)}
						disclaimer={fromMarkAst(data.disclaimer)}
						fieldset={data.subscription.collect}
						eula={
							<>
								By clicking {actionName} you agree to share your
								info with{' '}
								<InlineLink
									href={data.subscription.link}
									newTab
									underlined
								>
									{data.subscription.name}
								</InlineLink>{' '}
								&{' '}
								<InlineLink
									href={data.subscription.thanksLink}
									newTab
									underlined
								>
									Thanks
								</InlineLink>{' '}
								for use in accordance with their Privacy
								{'\u00A0'}Policies
							</>
						}
						{...prefilled}
						onInformationEnter={processData}
						linkCaption={data.linkCaption}
						image={slotImage}
						initialAgreedToThanksSubscription={
							impression.piiRegion !== 'GB'
						}
						giveaway={false}
						singleFieldSubExperiment={singleFieldSubExperiment}
					/>
				)}
			</SubscriptionController>
		);
	},
);

const HELLO_FRESH_US_ID = '8dd91283-bf80-435e-823e-31bd2c46d74f';
const FACTOR_US_ID = 'f3fffc27-462d-4009-8f89-9ae9a545af9c';

const thanksGiveawayRenderer = renderer(
	'thanks-giveaway',
	() => import('@zip/widget/offer'),
	(_type, { GiveawayOffer }, data, props, impression) => {
		const actionName = 'Enter now';

		const slotImage = (data.mg || impression.brand.mg)?.image_mobile || '';

		return (
			<WithLinkTracker url={data.href}>
				{(href) => (
					<ThanksGiveawayController
						impression={impression}
						offer={data}
						onNext={props.onNext}
					>
						{({ processData, prefilled }) => (
							<GiveawayOffer
								{...props}
								{...data}
								text={fromMarkAst(data.text)}
								subtext={fromMarkAst(data.subtext)}
								screen2Text={fromMarkAst(data.screen2Text)}
								screen2Subtext={fromMarkAst(
									data.screen2Subtext,
								)}
								disclaimer={fromMarkAst(data.disclaimer)}
								eula={
									<>
										By clicking {actionName} you agree to
										share your info with{' '}
										<InlineLink href="https://www.thanks.co/terms-and-privacy">
											Thanks
										</InlineLink>{' '}
										for use in accordance with their Privacy
										Policy.
										<br />
										{[
											HELLO_FRESH_US_ID,
											FACTOR_US_ID,
										].includes(data.advertiserId) &&
											'No purchase necessary to enter or win. Making a purchase will not improve your chances of winning.'}
									</>
								}
								{...prefilled}
								onInformationEnter={(data, options) => {
									window.open(href);

									return processData(data, options);
								}}
								linkCaption={data.linkCaption}
								image={slotImage}
							/>
						)}
					</ThanksGiveawayController>
				)}
			</WithLinkTracker>
		);
	},
);

const TYPES = composeFactory([
	offerRenderer,
	doubleOfferRenderer,
	codeRenderer,
	giftcardRenderer,
	subscriptionRenderer,
	thanksGiveawayRenderer,
] as const);

export const slotRender = createSlotRender(TYPES);
