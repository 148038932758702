import { VisuallyHidden } from '@smile/ds/a11y';
import { IconButton } from '@smile/ds/actions';
import { CloseIcon } from '@smile/ds/icons/Close';
import { PopupTeleporterTarget } from '@theway/shards';
import type { CSSProperties, FC, PropsWithChildren } from 'react';

import { AsteriskProvider } from '../control/state-controller';

import { ContextCloseTeleporter } from '../control/teleporters';

import { Header } from '../header';

import { Badge } from './Badge';
import { Content } from './Content';
import { Presentation } from './Presentation';
import * as styles from './styles';
import type { CommonProps, LayoutProps } from './types';

export const Layout: FC<
	PropsWithChildren<CommonProps & LayoutProps & { style?: CSSProperties }>
> = ({ onClose, children, style, ...props }) => {
	return (
		<AsteriskProvider hasAsterisk={Boolean(props.terms)}>
			<div className={styles.component} style={style}>
				<VisuallyHidden as="h1">
					{props.statusText} Here is your compliment. Please press ESC
					to close this window.
				</VisuallyHidden>
				<div className={styles.wrapper}>
					<Header {...props}>{props.statusText}</Header>
					<div className={styles.decoration}>
						<div className={styles.decorationWrapper}>
							<Presentation
								currentImage={props.currentImage}
								previousImage={props.previousImage}
							/>
						</div>
						{props.badge && <Badge>{props.badge}</Badge>}
					</div>
					<Content theme={props.theme} terms={props.terms}>
						{children}
					</Content>
				</div>
				<div className={styles.close}>
					<ContextCloseTeleporter.Target>
						<IconButton
							onClick={onClose}
							icon={<CloseIcon />}
							actionName="close"
							aria-label="Close thanks widget"
						>
							Close thanks ads
						</IconButton>
					</ContextCloseTeleporter.Target>
				</div>
				<PopupTeleporterTarget />
			</div>
		</AsteriskProvider>
	);
};
