import { clsx } from 'clsx';
import type { FC } from 'react';

import { thanks } from './const';
import * as styles from './styles';

export type PartnershipProps = {
	size?: number;
	brand: string;
	brandBackground?: string;
	ratio?: string;
};

export const Partnership: FC<PartnershipProps & { className?: string }> = ({
	className,
	brand,
	brandBackground = '#fff',
	size = 46,
	ratio = '78/36',
}) => {
	const Icon = () => (
		<svg
			className={styles.partnershipSvg}
			viewBox="0 0 151 63"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M119.56 0c-9.529 0-18.061 4.253-23.827 10.96-.8.791-1.598 1.592-2.397 2.392-7.605 7.636-19.385 9.746-29.112 5.123-1.804-.861-3.488-1.98-5.224-2.996.172.198.352.396.533.585 1.916 1.911 3.84 3.823 5.757 5.734 10.672 10.658 21.326 21.324 32.015 31.956l.035.035.636.636c.825.818 1.744 1.559 2.689 2.256.052.043.103.078.163.12l.542.388A31.242 31.242 0 0 0 119.56 63C136.925 63 151 48.899 151 31.5S136.925 0 119.56 0Z"
				fill={brandBackground}
			/>
			<path
				d="M31.098 63c9.426 0 17.865-4.253 23.568-10.96.79-.791 1.581-1.592 2.372-2.392 7.521-7.637 19.174-9.746 28.794-5.123 1.785.861 3.451 1.98 5.168 2.996-.17-.198-.349-.396-.527-.585-1.895-1.911-3.799-3.823-5.694-5.734C74.223 30.544 63.684 19.878 53.11 9.246l-.034-.034-.629-.638c-.816-.817-1.725-1.558-2.66-2.255-.051-.043-.102-.078-.162-.12l-.535-.388C44.008 2.161 37.804 0 31.098 0 13.921 0 0 14.101 0 31.5S13.921 63 31.098 63Z"
				fill="#002140"
			/>
		</svg>
	);

	return (
		<div
			className={clsx(className, styles.partnershipComponent)}
			style={{
				...styles.logoVar(size),
				...styles.bgVar(brandBackground),
				...styles.ratioVar(ratio),
			}}
		>
			<div
				className={styles.partnershipLogo}
				style={{ width: size }}
				dangerouslySetInnerHTML={{ __html: thanks }}
			/>
			{Icon()}
			<div className={styles.partnershipBrand}>
				<img src={brand} alt="" role="presentation" loading="eager" />
			</div>
		</div>
	);
};
