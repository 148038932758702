type NotDoublePromise<P> = P extends Promise<any> ? P : Promise<P>;

export type Lazied<T> = {
	[K in keyof T]: T[K] extends (...args: infer A) => infer R
		? (
				...args: A
			) => R extends void ? void | Promise<void> : NotDoublePromise<R>
		: T[K] extends Record<string, unknown>
			? Lazied<T[K]>
			: never;
};

export function lazyBridge<T extends Record<string, unknown>>(
	importer: () => Promise<T>,
): Lazied<T> {
	const deepAccess = (nodeControl: any) => {
		const stunt: any = {};

		const lazy = function () {
			// placeholder
		};

		return new Proxy(lazy, {
			async apply(_, thisArg, args: unknown[]) {
				return (await nodeControl()).call(thisArg, ...args);
			},
			get(_, property: any) {
				if (!stunt[property]) {
					stunt[property] = deepAccess(
						async () => (await nodeControl())[property],
					);
				}

				return stunt[property];
			},
		});
	};

	return deepAccess(() => importer()) as any;
}
