import type {
	ImpressionSequence,
	ThanksGiveawayItem,
} from '@thanks/impression-type';
import type { ReactNode } from 'react';

import { usePrefilled, type PrefilledInfo } from '../utils/hooks';

export const ThanksGiveawayController = ({
	children,
	impression,
	offer,
	onNext,
}: {
	children: (props: {
		prefilled: PrefilledInfo | undefined;
		processData: (
			data: Record<string, string>,
			options: { agreedToThanksSubscription: boolean },
		) => Promise<boolean>;
	}) => ReactNode;
	onNext(): void;
	impression: ImpressionSequence;
	offer: ThanksGiveawayItem;
}) => {
	const { prefilled } = usePrefilled({
		impressionId: impression.impressionId,
		offer,
		piiRegion: impression.piiRegion,
	});

	const processData = async (
		data: Record<string, string>,
		options: { agreedToThanksSubscription: boolean },
	) => {
		try {
			const url = new URLSearchParams({
				impression: impression.impressionId,
				offer: offer.experienceId,
				variant: offer.variant?.toString() || '',
			});
			const code = await fetch('/api/thanks-giveaway-entry?' + url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					...data,
					options,
				}),
			});

			if (!code.ok || code.status !== 200) {
				throw new Error('Failed to subscribe');
			}

			setTimeout(() => onNext(), 1500);

			return true;
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	return children({ processData, prefilled });
};
