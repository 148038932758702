import { css } from '@linaria/core';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const header = css`
	display: flex;
	flex-direction: column;
	row-gap: 8px;

	padding-inline: 24px;
	padding-top: 14px;
	padding-bottom: 16px;

	${container('content', 'width < 358px')} {
		padding-inline: 20px;
	}
`;

export const title = css`
	display: flex;
	align-items: center;
	column-gap: 4px;

	min-height: 20px;

	font-weight: 600;
`;
