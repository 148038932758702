import { palette } from '@smile/tokens/palette';
import classNames from 'clsx';

import { forwardRef, useRef } from 'react';
import type { ReactNode, CSSProperties } from 'react';

import { useMergeRefs } from 'use-callback-ref';

import {
	ColorSchemeProvider,
	ColorScheme,
	useColorScheme,
} from '../scheme/color';

import * as styles from './styles';
import { useOutlineColor } from './utils';

export type SurfaceColorType = 'primary' | 'secondary' | 'tertiary';

type ControlStyle<T extends keyof CSSProperties> = Pick<CSSProperties, T>;

export type SurfaceProps = {
	className?: string;
	as: any;
	color?: SurfaceColorType;
	children: ReactNode;
	onClick?(e: any): void;
	tabIndex?: number;
	role?: string;
	style?: ControlStyle<'backgroundColor'>;
};

const pickScheme = (color: SurfaceColorType): ColorScheme => {
	if (['tertiary'].includes(color)) {
		return ColorScheme.dark;
	}

	if (['secondary'].includes(color)) {
		return ColorScheme.contrast;
	}

	return ColorScheme.light;
};

export const Surface = forwardRef<HTMLElement, SurfaceProps>(function SurfaceFn(
	{
		className,
		as: Component,
		color = 'primary',
		children,
		onClick,
		tabIndex,
		role,
		style,
	},
	ref,
) {
	const targetScheme = pickScheme(color);

	const themeMode = useColorScheme(
		{
			light: styles.lightTheme,
			dark: styles.darkTheme,
			contrast: styles.contrastTheme,
		},
		targetScheme,
	);

	const surfaceRef = useRef(null);

	const componentStyle = useColorScheme(
		{
			light: {
				'--primary-action-background': `var(
	--custom-primary-action-bg,
	${palette.powerHouse[500]} 
)`,
			} as CSSProperties,
			dark: {
				'--primary-action-background': `var(
	--custom-secondary-action-bg,
	${palette.powerHouse[500]}
)`,
			} as CSSProperties,
			contrast: {
				'--primary-action-background': `var(
	--custom-tertiary-action-bg,
	${palette.baseBlue[500]}
)`,
			} as CSSProperties,
		},

		targetScheme,
	);

	const outlineColor = useOutlineColor(surfaceRef, componentStyle);

	const combinedRef = useMergeRefs([ref, surfaceRef]);

	const surface = (
		<Component
			className={classNames(
				styles.component,
				themeMode,
				styles[color],
				className,
			)}
			ref={combinedRef}
			onClick={onClick}
			tabIndex={tabIndex}
			role={role}
			style={{ ...style, ...outlineColor } as CSSProperties}
		>
			{children}
		</Component>
	);

	if (targetScheme) {
		return (
			<ColorSchemeProvider scheme={targetScheme}>
				{surface}
			</ColorSchemeProvider>
		);
	}

	return surface;
});
