export const thanks = `
	<svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="var(--custom-thanks-bg-color, #002140)"/>
<path d="M11.8703 10.8032H11.5358C11.0788 10.8032 10.7084 10.4328 10.7084 9.97584V8.19708H10.5207C10.0638 8.19708 9.69336 7.82664 9.69336 7.36971V7.05149C9.69336 6.59456 10.0638 6.22412 10.5207 6.22412H12.8853C13.3423 6.22412 13.7127 6.59456 13.7127 7.05149V7.36971C13.7127 7.82664 13.3423 8.19708 12.8853 8.19708H12.6977V9.97584C12.6977 10.4328 12.3272 10.8032 11.8703 10.8032Z" fill="var(--custom-thanks-color, #FEA8E1)"/>
<path d="M17.701 10.8032H17.3648C16.9079 10.8032 16.5374 10.4328 16.5374 9.97584V9.48954H16.4216V9.97584C16.4216 10.4328 16.0511 10.8032 15.5942 10.8032H15.258C14.8011 10.8032 14.4307 10.4328 14.4307 9.97584V7.05149C14.4307 6.59456 14.8011 6.22412 15.258 6.22412H15.5942C16.0511 6.22412 16.4216 6.59456 16.4216 7.05149V7.51658H16.5374V7.05149C16.5374 6.59456 16.9079 6.22412 17.3648 6.22412H17.701C18.1579 6.22412 18.5283 6.59456 18.5283 7.05149V9.97584C18.5283 10.4328 18.1579 10.8032 17.701 10.8032Z" fill="var(--custom-thanks-color, #FEA8E1)"/>
<path d="M13.1122 16.1296H12.7531C12.417 16.1296 12.1151 15.9256 11.9878 15.6156L11.8654 15.3153H11.5309L11.4101 15.6139C11.2844 15.9256 10.9809 16.1296 10.6431 16.1296H10.2939C10.0181 16.1296 9.76026 15.9925 9.60686 15.7624C9.45346 15.534 9.42409 15.2435 9.52853 14.9873L10.7296 12.063C10.8569 11.7529 11.1588 11.5505 11.495 11.5505H11.9111C12.2456 11.5505 12.5492 11.7529 12.6764 12.063L13.8775 14.9873C13.982 15.2419 13.9526 15.5324 13.7992 15.7624C13.6458 15.9909 13.388 16.1296 13.1122 16.1296Z" fill="var(--custom-thanks-color, #FEA8E1)"/>
<path d="M17.7062 16.1296H17.4027C17.1465 16.1296 16.9049 16.0105 16.7483 15.8081L16.4154 15.3773C16.3778 15.8 16.0237 16.1296 15.5913 16.1296H15.2551C14.7982 16.1296 14.4277 15.7592 14.4277 15.3023V12.3779C14.4277 11.921 14.7982 11.5505 15.2551 11.5505H15.5586C15.8148 11.5505 16.0564 11.6697 16.213 11.872L16.5459 12.3028C16.5835 11.8802 16.9376 11.5505 17.37 11.5505H17.7062C18.1631 11.5505 18.5336 11.921 18.5336 12.3779V15.3023C18.5336 15.7592 18.1631 16.1296 17.7062 16.1296Z" fill="var(--custom-thanks-color, #FEA8E1)"/>
<path d="M12.9161 21.6977H12.4592C12.2372 21.6977 12.0251 21.608 11.8684 21.4497L11.5959 21.1723C11.4751 21.4807 11.1765 21.6977 10.8256 21.6977H10.4895C10.0325 21.6977 9.66211 21.3273 9.66211 20.8704V17.946C9.66211 17.4891 10.0325 17.1187 10.4895 17.1187H10.8256C11.1602 17.1187 11.449 17.3177 11.5796 17.6033L11.7885 17.3798C11.9451 17.2133 12.1638 17.1187 12.3923 17.1187H12.8263C13.1576 17.1187 13.4563 17.3161 13.5868 17.6213C13.7174 17.9264 13.6521 18.2789 13.4236 18.5172L12.6044 19.3723L13.5052 20.2894C13.7386 20.5277 13.8071 20.8818 13.6782 21.1886C13.5493 21.4954 13.249 21.6961 12.9161 21.6961V21.6977Z" fill="var(--custom-thanks-color, #FEA8E1)"/>
<path d="M16.4772 21.7761C15.8701 21.7761 15.2288 21.5607 14.8045 21.2131C14.4716 20.9406 14.403 20.4592 14.6462 20.1051L14.8306 19.8375C14.8518 19.8081 14.873 19.7787 14.8975 19.7526C14.6837 19.4687 14.594 19.1309 14.594 18.8143C14.594 17.8335 15.4066 17.1204 16.5278 17.1204C17.094 17.1204 17.6064 17.2787 18.0519 17.5887C18.4142 17.8417 18.5138 18.3345 18.2788 18.7082L18.1074 18.9791C18.0944 19.0003 18.0797 19.0199 18.065 19.0395C18.3228 19.3136 18.4632 19.6694 18.4632 20.0676C18.4632 21.0728 17.6472 21.7729 16.4788 21.7729L16.4772 21.7761Z" fill="var(--custom-thanks-color, #FEA8E1)"/>
</g>
</svg>
	`;
