import { Logo } from '@smile/ds/logo';
import { TextSm } from '@smile/ds/typography';
import type { FC, PropsWithChildren } from 'react';

import { Pagination } from '../pagination';
import type { PaginationControlProps } from '../pagination';

import * as styles from './styles';

export const Header: FC<PropsWithChildren & PaginationControlProps> = ({
	children,
	...props
}) => {
	return (
		<div className={styles.header}>
			<div className={styles.title}>
				<Logo theme="light" width={24} />
				<TextSm as="p">{children}</TextSm>
			</div>
			<Pagination {...props} />
		</div>
	);
};
