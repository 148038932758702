import { css } from '@linaria/core';
import { font } from '@smile/tokens/font';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const component = css`
	font-family: ${font.family.primary};

	${container('content', 'width < 358px')} {
		zoom: 0.96;
	}
`;

export const h1 = css`
	font-size: 38px;
	line-height: 38px;
`;

export const h2 = css`
	font-size: 30px;
	line-height: 34px;

	& strong {
		font-weight: ${font.weight.black};
	}
`;

export const h3 = css`
	font-size: 24px;
	line-height: 28px;

	& strong {
		font-weight: ${font.weight.bold};
	}
`;

export const h4 = css`
	font-size: 20px;
	line-height: 24px;

	& strong {
		font-weight: ${font.weight.bold};
	}
`;

export const textXs = css`
	font-size: 10px;
	line-height: 12px;

	& strong {
		font-weight: ${font.weight.bold};
	}
`;

export const textSm = css`
	font-size: 12px;
	line-height: 16px;

	& strong {
		font-weight: ${font.weight.bold};
	}
`;

export const textMd = css`
	font-size: 16px;
	line-height: 20px;

	& strong {
		font-weight: ${font.weight.bold};
	}
`;

export const regular = css`
	font-weight: ${font.weight.regular};
`;

export const medium = css`
	font-weight: ${font.weight.medium};
`;

export const bold = css`
	font-weight: ${font.weight.bold};
`;

export const black = css`
	font-weight: ${font.weight.black};
`;

export const primaryColor = css`
	color: var(--text-primary);
`;

export const mutedColor = css`
	color: var(--text-muted);
`;

export const left = css`
	text-align: left;
`;

export const right = css`
	text-align: right;
`;

export const center = css`
	text-align: center;
`;
