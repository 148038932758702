import { css } from '@linaria/core';

export const lightTheme = css`
	//primary
	--primary-color: #ffffff;
	--primary-background: #000000;
	--primary-color-disabled: #808080;
	--primary-background-disabled: #e5e5e5;
	--primary-color-pressed: #ffffff;
	--primary-background-pressed: #333333;

	//secondary
	--secondary-color: #000000;
	--secondary-background: transparent;
	--secondary-color-disabled: #999999;
	--secondary-background-disabled: transparent;
	--secondary-color-pressed: #000000;
	--secondary-background-pressed: #e5e5e5;
`;

export const darkTheme = css``;

export const contrastTheme = css``;

export const component = css`
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 8px;

	height: 50px;
	padding-inline: 20px;

	box-sizing: border-box;

	font-family: inherit;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;

	border-radius: 9999px;
	border: none;

	& svg {
		aspect-ratio: 1/1;
		width: 20px;

		color: inherit;
	}
`;

export const primary = css`
	color: var(--primary-color);

	background-color: var(--primary-background);

	&:active {
		background-color: var(--primary-background-pressed);
	}

	&:disabled {
		color: var(--primary-color-disabled);

		background-color: var(--primary-background-disabled);
	}
`;

export const secondary = css`
	color: var(--secondary-color);

	background-color: var(--secondary-background);

	&:active {
		background-color: var(--secondary-background-pressed);
	}

	&:disabled {
		color: var(--secondary-color-disabled);

		background-color: var(--secondary-background-disabled);
	}
`;

export const fullWidth = css`
	width: 100%;
`;

export const icon = css`
	aspect-ratio: 1/1;
	width: 32px;
	padding: 0;

	border-radius: 50% !important;
`;

export const iconBefore = css``;

export const iconAfter = css``;
