export const logo = `
<svg height='100%' viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="var(--bg-color, #FEA8E1)"/>
<path d="M21.1975 19.2912H20.6001C19.7841 19.2912 19.1226 18.6297 19.1226 17.8138V14.6374H18.7875C17.9716 14.6374 17.3101 13.9759 17.3101 13.16V12.5917C17.3101 11.7758 17.9716 11.1143 18.7875 11.1143H23.01C23.826 11.1143 24.4875 11.7758 24.4875 12.5917V13.16C24.4875 13.9759 23.826 14.6374 23.01 14.6374H22.6749V17.8138C22.6749 18.6297 22.0134 19.2912 21.1975 19.2912Z" fill="var(--color, #002140)"/>
<path d="M31.6094 19.2912H31.0091C30.1931 19.2912 29.5316 18.6297 29.5316 17.8138V16.9454H29.3247V17.8138C29.3247 18.6297 28.6632 19.2912 27.8473 19.2912H27.247C26.431 19.2912 25.7695 18.6297 25.7695 17.8138V12.5917C25.7695 11.7758 26.431 11.1143 27.247 11.1143H27.8473C28.6632 11.1143 29.3247 11.7758 29.3247 12.5917V13.4222H29.5316V12.5917C29.5316 11.7758 30.1931 11.1143 31.0091 11.1143H31.6094C32.4253 11.1143 33.0868 11.7758 33.0868 12.5917V17.8138C33.0868 18.6297 32.4253 19.2912 31.6094 19.2912Z" fill="var(--color, #002140)"/>
<path d="M23.4149 28.8029H22.7738C22.1735 28.8029 21.6343 28.4387 21.407 27.885L21.1885 27.3488H20.5911L20.3755 27.8821C20.1511 28.4387 19.6091 28.8029 19.0058 28.8029H18.3822C17.8897 28.8029 17.4293 28.5581 17.1554 28.1472C16.8815 27.7393 16.829 27.2206 17.0155 26.7631L19.1603 21.541C19.3876 20.9873 19.9267 20.626 20.527 20.626H21.2701C21.8675 20.626 22.4095 20.9873 22.6368 21.541L24.7816 26.7631C24.9681 27.2177 24.9156 27.7364 24.6417 28.1472C24.3678 28.5552 23.9073 28.8029 23.4149 28.8029Z" fill="var(--color, #002140)"/>
<path d="M31.6181 28.8029H31.0761C30.6185 28.8029 30.1873 28.5902 29.9075 28.2288L29.313 27.4595C29.246 28.2143 28.6137 28.8029 27.8414 28.8029H27.2411C26.4252 28.8029 25.7637 28.1414 25.7637 27.3255V22.1034C25.7637 21.2875 26.4252 20.626 27.2411 20.626H27.7831C28.2406 20.626 28.6719 20.8387 28.9517 21.2001L29.5462 21.9694C29.6132 21.2146 30.2455 20.626 31.0178 20.626H31.6181C32.434 20.626 33.0955 21.2875 33.0955 22.1034V27.3255C33.0955 28.1414 32.434 28.8029 31.6181 28.8029Z" fill="var(--color, #002140)"/>
<path d="M23.0651 38.7458H22.2491C21.8528 38.7458 21.474 38.5855 21.1942 38.3028L20.7076 37.8075C20.492 38.3582 19.9587 38.7458 19.3321 38.7458H18.7318C17.9159 38.7458 17.2544 38.0843 17.2544 37.2683V32.0463C17.2544 31.2303 17.9159 30.5688 18.7318 30.5688H19.3321C19.9295 30.5688 20.4453 30.9244 20.6785 31.4343L21.0515 31.0351C21.3312 30.7379 21.7217 30.5688 22.1297 30.5688H22.9048C23.4964 30.5688 24.0297 30.9215 24.2628 31.4664C24.4959 32.0113 24.3794 32.6408 23.9714 33.0662L22.5085 34.5932L24.1171 36.2309C24.5338 36.6564 24.6562 37.2887 24.426 37.8366C24.1958 38.3844 23.6596 38.7429 23.0651 38.7429V38.7458Z" fill="var(--color, #002140)"/>
<path d="M29.4239 38.8857C28.3399 38.8857 27.1946 38.501 26.437 37.8803C25.8425 37.3937 25.7201 36.534 26.1543 35.9017L26.4836 35.4237C26.5215 35.3713 26.5594 35.3188 26.6031 35.2722C26.2213 34.7652 26.0611 34.1619 26.0611 33.5966C26.0611 31.8452 27.5123 30.5718 29.5143 30.5718C30.5255 30.5718 31.4405 30.8544 32.236 31.4081C32.883 31.8598 33.0607 32.7399 32.6411 33.4072L32.3351 33.8909C32.3118 33.9288 32.2856 33.9638 32.2593 33.9987C32.7198 34.4883 32.9704 35.1236 32.9704 35.8346C32.9704 37.6297 31.5133 38.8799 29.4268 38.8799L29.4239 38.8857Z" fill="var(--color, #002140)"/>
</svg>
`;
