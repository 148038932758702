import { seed } from './consts';
import type { Callback } from './types';

export const decodeMessage = (
	msg: any,
):
	| { cmd: string; payload: unknown; error: false }
	| { cmd: unknown; payload: unknown; error: true } => {
	if (typeof msg === 'object') {
		if (
			'seed' in msg &&
			msg.seed === seed &&
			'cmd' in msg
			// 'payload' in msg // payload might be removed after JSON transformation
		) {
			return msg as any;
		}
	}

	return { error: true } as any;
};

export const encodeMessage = (cmd: string, payload: any) => ({
	cmd,
	payload,
	seed,
});

export const executeEvent = (
	events: Record<string, Callback[]>,
	cmd: string,
	payload: any,
) => {
	(events[cmd] || []).forEach((cb) => cb(payload));
};
